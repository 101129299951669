import React from 'react'

function About() {
    return (
        <React.Fragment>
            <h1>Quotes Project</h1>
            <p>Quotes Project</p>
        </React.Fragment>
    )
}

export default About
