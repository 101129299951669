import React, { Component } from 'react'
import './Canvas.css'
import ExpDate from './ExpDate';
import ChangeStock from './ChangeStock';
//import OptionSymbols from './OptionSymbols';
//import OptionSymbolsCalls from './OptionSymbolsCalls';
import CallPrices from './CallPrices';
import PutPrices from './PutPrices';
import CallsHeader from './layout/CallsHeader';
import PutsHeader from './layout/PutsHeader';
import ExpDateHeader from './layout/ExpDateHeader';
import ChangeStockHeader from './layout/ChangeStockHeader';
import CustomAlert from './layout/CustomAlert';
//import OptionSymbolsPuts from './OptionSymbolsPuts';
import axios from 'axios';

class Canvas extends Component {

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            quotes: [],
            stockQuotes: [],
            canvasWidth: 2000,
            canvasHeight: 500,
            expDateMapWidth: 400,
            expDateMapHeight: 500,
            quoteDateTimeMap: [],
            reverseQuoteDateTimeMap: [],
            quoteDateTimeRatio: 0,
            minQuoteDateTime: 0,
            maxQuoteDateTime: 0,
            minQuoteDateTimeIn5MinInc: 0,
            maxQuoteDateTimeIn5MinInc: 0,
            minStockPrice: 0,
            maxStockPrice: 0,
            underlyingLastRatio: 0,
            optionSymbolsSet: [],
            expirationDateArray: [],
            expirationDateQuotesMap: [],
            expirationDateQuotesArray: [],
            optionSymbolsToChart: [],
            optionQuotesChartMap: [],
            xMap: [], // X Position, quoteDateTimeIn5MinsInc - for use on mouse move
            // We have a xMapReverse in canvasUpdate but we haven't found a reason to put it in State yet
            yMap: [], // X Position, Y Position - for use on mouse move
            //xMapQuoteDateTimeIn5MinIncOnMouseMove: 0, // changes when mouse moves
            formattedDate: '', // for when mouse moves
            canvasMouseMoveChangesEnabled: true,
            // TO DO WITH THE CURRENT QUOTES:
            callsMap: [],
            putsMap: [],
            callsMapByStrike: [],
            putsMapByStrike: [],
            displayCallsMap: [],
            displayPutsMap: [],
            current5MinQdt: 0,
            expDate: 0,
            expDateUnixTime: 0,
            expirationDateArray: [],
            // callStrikeSet: [],
            // putStrikeSet: [],
            callStrikeArray: [], // used to keep track of all the current call Strike Prices
            putStrikeArray: [], // used to keep track of all the current put Strike Prices
            maxBidPctIncCallsBy5MinsMap: [],
            maxBidPctIncPutsBy5MinsMap: [],
            maxCallStrikePrice: 0,
            minCallStrikePrice: 999999,
            maxPutStrikePrice: 0,
            minPutStrikePrice: 999999,
            displayAlertIsActive: false,
            customAlertData: [123],
            expDateSetIsShown: false,
            changeStockIsShown: false
        }
        this.handleOnMouseMove = this.handleOnMouseMove.bind(this);
        this.onClick = this.onClick.bind(this);
        this.expDateSetIsShownChange = this.expDateSetIsShownChange.bind(this);
        this.changeStock = this.changeStock.bind(this);
        //this.onScroll = this.onScroll.bind(this);
        this.updateCanvas = this.updateCanvas.bind(this);
        this.customAlertFunction = this.customAlertFunction.bind(this);
        this.changeAlert = this.changeAlert.bind(this);
        this.canvas = React.createRef();
        this.canvas2 = React.createRef(); // FOR MOUSE MOVE LINES AND TEXT
        this.canvas3 = React.createRef(); // VERTICAL LINES FOR CERTAIN INCREASES
        this.canvas4 = React.createRef(); // STROKE BOX AFTER EXP DATE
        this.canvas5 = React.createRef(); // VERTICAL LINES FOR CERTAIN INCREASES
        this.canvas6 = React.createRef(); // FOR QUOTEDATETIME MENU AND STOCK PRICES MENU
        this.stockMenu = React.createRef();
        this.item2 = React.createRef();
    }



    // checkQuoteDateTime(quote) {
    //     console.log('checkQuoteDateTime - value: ', quote);
    //     if (quote.quoteDateTime == value) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    componentDidUpdate(prevProps) {
        //console.log('Canvas componentDidUpdate - prevProps: ', prevProps);
        //console.log('Canvas componentDidUpdate - this.props: ', this.props);
        //this.updateCanvas(this.props.stockQuotesMap, null); // system doesn't like nesting the setState
    }

    async componentDidMount() {
        //console.log('this.state.stockQuotes.length: ', this.state.stockQuotes.length);
        //console.log('Canvas componentsDidMount: this.props.stockQuotesMap.size: ', this.props.stockQuotesMap.size);
        //console.log('this.props: ', this.props);
        ////const optionSymbolsSet = [...new Set(this.props.quotes.map(item => item.optionSymbol))];

        if ((this.props.stockQuotesMap.size === 0) || (this.props.stockQuotesMap.size === undefined)) {
            await this.props.getStockSymbol('KO');
            //console.log('Canvas componentDidMount about to update canvas');
            //this.updateCanvas(this.props.stockQuotesMap, null);
        }

        //if (this.state.stockQuotes.length == 0) {getStockSymbol={this.getStockSymbol.bind(this)} 
        //let flags = [], output = [], l = this.props.quotes.length, i;
        // let quoteDateTimeSet = new Set();
        // for (i = 0; i < l; i++) {

        // }
        ////const quoteDateTimesSet = this.props.quoteDateTimesSet;
        ////const quoteDateTimesSet = [...new Set(this.props.quotes.map(item => item.quoteDateTime))];
        ////console.log('quoteDateTimesSet: ', quoteDateTimesSet);
        ////var stockQuoteArray = new Array();
        ////let stockQuotesMap = this.props.stockQuotesMap;
        // let stockQuoteMap = new Map();
        // quoteDateTimesSet.map((value) => {
        //     //console.log('value: ', value);
        //     //let newObject;
        //     for (let i = 0; i < this.props.quotes.length; i++) {
        //         if (this.props.quotes[i].quoteDateTime == value) {
        //             let newObject = new Object();
        //             newObject.underlyingLast = this.props.quotes[i].underlyingLast;
        //             newObject.quoteDateTime = value;
        //             //console.log('found newObject; ', newObject);
        //             //this.setState({ stockQuotes: [...this.state.stockQuotes,  )
        //             stockQuoteMap.set(Math.round(value / 300), this.props.quotes[i].underlyingLast);
        //             stockQuoteArray.push(newObject);
        //             break;
        //         }
        //     }
        // })
        // console.log('stockQuoteArray: ', stockQuoteArray);


        // let expirationDateSet = new Set();
        // let expirationDateQuotesMap = new Map();
        // console.log('optionSymbolsSet: ', optionSymbolsSet);
        // let id = 1000;
        // optionSymbolsSet.forEach((optionSymbol) => {
        //     let expiration = optionSymbol.substr(optionSymbol.indexOf("2"), 6);
        //     expirationDateSet.add(expiration);
        //     let putOrCallLetter = optionSymbol.substr(optionSymbol.indexOf("2") + 6, 1);
        //     //console.log('putOrCallLetter: ', putOrCallLetter);
        //     let putOrCall = '';
        //     if (putOrCallLetter == 'P') {
        //         putOrCall = 'Put';
        //     } else if (putOrCallLetter == 'C') {
        //         putOrCall = 'Call';
        //     } else {
        //         console.log('putOrCall Error');
        //         throw 1;
        //     }
        //     //console.log('putOrCall: ', putOrCall);
        //     if (expirationDateQuotesMap.has(expiration)) {
        //         let thisQuoteArray = expirationDateQuotesMap.get(expiration);
        //         let newObject = {
        //             id: id,
        //             optionSymbol: optionSymbol,
        //             selected: false,
        //             putOrCall: putOrCall
        //         }
        //         thisQuoteArray.push(newObject);
        //         expirationDateQuotesMap.set(expiration, thisQuoteArray);
        //         id++;
        //     } else {
        //         let newArray = new Array();
        //         let newObject = {
        //             id: id,
        //             optionSymbol: optionSymbol,
        //             selected: false,
        //             putOrCall: putOrCall
        //         }
        //         newArray.push(newObject);
        //         expirationDateQuotesMap.set(expiration, newArray);
        //         id++;
        //     }
        // })

        // let expirationDateQuotesArray = new Array();
        // id = 0;
        // expirationDateQuotesMap.forEach((value, key, map) => {
        //     console.log('value: ', value);
        //     console.log('key: ', key);
        //     console.log('map: ', map);
        //     let object = {
        //         id: id,
        //         selected: false,
        //         expDate: key,
        //         optionSymbolArray: value
        //     }
        //     expirationDateQuotesArray.push(object);
        //     id++;
        // })
        // console.log('expirationDateSet: ', expirationDateSet);
        // console.log('expirationDateQuotesMap: ', expirationDateQuotesMap);
        // console.log('expirationDateQuotesArray: ', expirationDateQuotesArray);
        // let expirationDateArray = new Array();
        // // id = 0;
        // if (expirationDateSet.size > 0) {
        //     expirationDateSet.forEach((expDate) => {
        //         let expDateObject = {
        //             id: { id },
        //             expDate: expDate,
        //             selected: false
        //         }
        //         expirationDateArray.push(expDateObject);
        //         id++;
        //     });
        // }
        // this.setState({ expirationDateArray, expirationDateArray });
        // this.setState({ expirationDateSet, expirationDateArray });
        // this.setState({ expirationDateQuotesMap, expirationDateQuotesMap });
        // this.setState({ expirationDateQuotesArray, expirationDateQuotesArray });


        // //const optionSymbolsSet = [...new Set(this.props.quotes.map(item => item.optionSymbols))];
        // //this.setState({ stockQuotes: [...this.state.stockQuotes, ...stockQuoteArray] })
        // this.setState({ optionSymbolsSet: optionSymbolsSet })
        // this.setState({ stockQuotes: stockQuoteArray })
        // this.setState({ stockQuoteMap: stockQuoteMap })
        // this.setState({
        //     stockQuotes: stockQuoteArray.toString()
        // });
        // this.props.Quotes.map((line) => {
        //     //console.log(line.dateTime)
        //     //line.dateTime = new Date(line.dateTime * 1000).toString
        //     line.id = uuid();
        // });
        //}
        this.updateCanvas(this.props.stockQuotesMap, null);
        console.log('componentDidMount this.props.stockQuotesMap.size: ', this.props.stockQuotesMap.size);
    }

    updateCanvas(stockQuoteArray, arrayOfSymbolsToChart) {
        this.setState({ expirationDateArray: this.props.expirationDateArray });
        //console.log('updateCanvas this.state.optionSymbolsToChart: ', this.state.optionSymbolsToChart);
        const context = this.canvas.current.getContext('2d');
        //const callContext = this.canvas.current.getContext('2d');
        //const putContext = this.canvas.current.getContext('2d');
        let canvasWidth = this.state.canvasWidth;
        let canvasHeight = this.state.canvasHeight;
        context.clearRect(0, 0, canvasWidth, canvasHeight);
        //ctx.fillRect(0, 0, 500, 500);
        // for (int i = 0; i < stockQuoteArray.length; i++) {
        //     console.log('stockQuoteArray[i]: ', stockQuoteArray[i]);
        // }
        // set the style for the stroke() call below
        context.strokeStyle = 'rgba(255,0,0,0.5)';          // now: strokeStyle instead of fillStyle
        // the same path again as before:
        // STROKE BOX AROUND CANVAS
        context.beginPath();
        context.moveTo(0, 0);
        context.lineTo(canvasWidth, 0);
        context.lineTo(canvasWidth, canvasHeight);
        context.lineTo(0, canvasHeight);
        context.lineTo(0, 0);
        context.stroke();                                 // now: stroke() insted of fill()
        context.closePath();

        //console.log('this.props.stockQuotesMap.size in updateCanvas: ', this.props.stockQuotesMap.size);
        let minQuoteDateTime = 99999999999;
        let maxQuoteDateTime = -1;
        //let minQuoteDateTimeIn5MinInc = 99999999999;
        //let maxQuoteDateTimeIn5MinInc = -1;
        //let minStockPrice = 99999999999;
        //let maxStockPrice = -1;
        //console.log('this.props.stockQuotesMap: ', this.props.stockQuotesMap);
        //this.props.stockQuotesDateSet.forEach((quote, index) => {
        // this.props.stockQuotesMap.forEach((stockPrice, quoteDateTimeIn5MinInc) => {
        //     //console.log('stockPrice,quoteDateTime: ', stockPrice, quoteDateTime);
        //     //let quoteDateTime = quoteDateTimeIn5MinInc * 300;
        //     if (quoteDateTimeIn5MinInc > maxQuoteDateTimeIn5MinInc) {
        //         maxQuoteDateTimeIn5MinInc = quoteDateTimeIn5MinInc;
        //     }
        //     if (quoteDateTimeIn5MinInc < minQuoteDateTimeIn5MinInc) {
        //         minQuoteDateTimeIn5MinInc = quoteDateTimeIn5MinInc;
        //     }
        //     if (stockPrice > maxStockPrice) {
        //         maxStockPrice = stockPrice;
        //     }
        //     if (stockPrice < minStockPrice) {
        //         minStockPrice = stockPrice;
        //     }
        // });

        let stockQuotesArray = [...this.props.stockQuotesMap.values()];
        let quoteDateTimeIn5MinIncArray = [...this.props.stockQuotesMap.keys()];
        let maxQuoteDateTimeIn5MinInc = Math.max(...quoteDateTimeIn5MinIncArray);
        let minQuoteDateTimeIn5MinInc = Math.min(...quoteDateTimeIn5MinIncArray);
        let maxStockPrice = Math.max(...stockQuotesArray);
        let minStockPrice = Math.min(...stockQuotesArray);

        // console.log('maxQuoteDateTimeIn5MinInc: ', maxQuoteDateTimeIn5MinInc);
        // console.log('minQuoteDateTimeIn5MinInc: ', minQuoteDateTimeIn5MinInc);
        // console.log('maxStockPrice: ', maxStockPrice);
        // console.log('minStockPrice: ', minStockPrice);


        //this.props.stockQuotesMap.reduce((something) => )

        console.log('updateCanvas minQuoteDateTime: ', minQuoteDateTime);
        console.log('updateCanvas maxQuoteDateTime: ', maxQuoteDateTime);
        console.log('updateCanvas minQuoteDateTimeIn5MinInc: ', minQuoteDateTimeIn5MinInc);
        console.log('updateCanvas maxQuoteDateTimeIn5MinInc: ', maxQuoteDateTimeIn5MinInc);
        console.log('updateCanvas minQuoteDate: ', new Date(minQuoteDateTimeIn5MinInc * 300 * 1000));
        console.log('updateCanvas maxQuoteDate: ', new Date(maxQuoteDateTimeIn5MinInc * 300 * 1000));
        console.log('updateCanvas minStockPrice: ', minStockPrice);
        console.log('updateCanvas maxStockPrice: ', maxStockPrice);
        this.setState({ minQuoteDateTime: minQuoteDateTime });
        this.setState({ maxQuoteDateTime: maxQuoteDateTime });
        this.setState({ minQuoteDateTimeIn5MinInc: minQuoteDateTimeIn5MinInc });
        this.setState({ maxQuoteDateTimeIn5MinInc: maxQuoteDateTimeIn5MinInc });
        this.setState({ minStockPrice: minStockPrice });
        this.setState({ maxStockPrice: maxStockPrice });
        let quoteDateTimeDifIn5MinInc = (maxQuoteDateTimeIn5MinInc - minQuoteDateTimeIn5MinInc); // seconds - now using 5 minute increments
        console.log('updateCanvas quoteDateTimeDifIn5MinInc: ', quoteDateTimeDifIn5MinInc);
        //let quoteDateTimeRatio = canvasWidth / quoteDateTimeDif;
        let underlyingLastRatio = canvasHeight / (maxStockPrice - minStockPrice);
        this.setState({ underlyingLastRatio: underlyingLastRatio });

        // WE WILL START BY ASSUMING THE US IS IN EDT NOT EST - FIX THIS LATER
        // USING EDT FOR CODING - MUST CHANGE TO UTC FOR BUILD
        // GETTING QUOTEDATETIME WITHOJT WEEKENDS AND EVENINGS
        let startingTime = Date.now();
        let quoteDateTimeMap = new Map();
        let reverseQuoteDateTimeMap = new Map();
        let xPosition = 0;
        for (let quoteDateTimeIn5MinInc = minQuoteDateTimeIn5MinInc; quoteDateTimeIn5MinInc <= maxQuoteDateTimeIn5MinInc; quoteDateTimeIn5MinInc++) {
            let withinTradingTime = false;
            let thisDate = new Date(quoteDateTimeIn5MinInc * 300 * 1000);
            let thisDay = thisDate.getDay();
            if ((thisDay === 0) || (thisDay === 6)) { // Sat or Sun
                continue;
            }
            let thisHour = thisDate.getHours();
            //console.log('thisHour: ', thisHour);
            if ((thisHour > 8) && (thisHour < 17)) { // greater than 8AM and less then 5PM
                if (thisHour === 9) { // 9AM
                    let thisMinutes = thisDate.getMinutes();
                    //console.log('thisMinutes: ', thisMinutes);
                    if (thisMinutes > 45) {
                        withinTradingTime = true;
                    }
                } else if (thisHour === 16) { // 4PM
                    let thisMinutes = thisDate.getMinutes();
                    //console.log('2thisMinutes: ', thisMinutes);
                    if (thisMinutes < 20) {
                        withinTradingTime = true;
                    }
                } else {
                    //console.log('no minutes used');
                    withinTradingTime = true;
                }
            }
            if (withinTradingTime === true) {
                //console.log('setting in the map');
                quoteDateTimeMap.set(quoteDateTimeIn5MinInc, xPosition);
                reverseQuoteDateTimeMap.set(xPosition, quoteDateTimeIn5MinInc);
                xPosition++
            }
            // if (i == 1598277300) {
            //     console.log('i: ', i);
            //     throw 1;
            // }
        }
        let maxXPosition = xPosition;
        //let canvasQuoteDateTimeDif = maxXPosition;
        let quoteDateTimeRatio = canvasWidth / maxXPosition;
        //let quoteDateTimeRatio = canvasWidth / quoteDateTimeDifIn5MinInc;
        this.setState({ quoteDateTimeRatio: quoteDateTimeRatio });
        //this.setState({ quoteDateTimeMap: [...this.state.quoteDateTimeMap, ...quoteDateTimeMap] })
        this.setState({ quoteDateTimeMap: quoteDateTimeMap })
        this.setState({ reverseQuoteDateTimeMap: reverseQuoteDateTimeMap })
        console.log('updateCanvas xPosition: ', xPosition);
        console.log('updateCanvas quoteDateTimeMap: ', quoteDateTimeMap);

        // expirationDateQuotesArray.map((expDateObject) => {
        //     console.log('Canvas updateCanvas expDateObject: ', expDateObject);
        // })

        //let optionPricesMap = new Map();
        //let reverseQuoteDateTimeMap = new Map();
        //let xPosition = 0;


        let secondsUsed = Math.round((Date.now() - startingTime) / 1000);
        console.log('updateCanvas seconds used: ', secondsUsed);
        console.log("updateCanvas Minutes used: " + Math.floor(secondsUsed / 60) + "  Seconds used: " + (secondsUsed - (60 * Math.floor(secondsUsed / 60))));
        //throw 1;

        console.log('updateCanvas quoteDateTimeRatio: ', quoteDateTimeRatio);
        console.log('updateCanvas underlyingLastRatio: ', underlyingLastRatio);

        //console.log('updateCanvas 2stockQuoteArray.length in updateCanvas: ', stockQuoteArray.length);
        //console.log('updateCanvas 2this.props.stockQuotesMap.size in updateCanvas: ', this.props.stockQuotesMap.size);

        context.beginPath(); // STROKE THE STOCK PRICES
        context.strokeStyle = '#3e4444';
        //stockQuoteArray.forEach((quote, index) => {
        // STROKE THE STOCK PRICES
        let xMap = new Map(); // x Position, quoteDateTimeIn5MinInc
        let xMapReverse = new Map(); // quoteDateTimeIn5MinInc, x Position
        let yMap = new Map(); // x Position, y Position
        context.font = "18px Arial";
        this.props.stockQuotesMap.forEach((stockPrice, quoteDateTimeIn5MinInc) => {
            //console.log('quote.quoteDateTime: ', quote.quoteDateTime);
            //console.log('quoteDateTimeRatio: ', quoteDateTimeRatio);
            //let x = ((quote.quoteDateTime - minQuoteDateTime) * quoteDateTimeRatio);
            //let x = Math.round(quoteDateTimeMap.get(quote.quoteDateTime) * quoteDateTimeRatio);
            let x = Math.round(quoteDateTimeMap.get(quoteDateTimeIn5MinInc) * quoteDateTimeRatio);
            //let y = canvasHeight - ((quote.underlyingLast - minStockPrice) * underlyingLastRatio);
            let y = Math.round(canvasHeight - ((stockPrice - minStockPrice) * underlyingLastRatio));
            xMap.set(x, quoteDateTimeIn5MinInc); // could put them both into 1 object
            xMapReverse.set(quoteDateTimeIn5MinInc, x);
            yMap.set(x, y);
            // console.log('STROKE STOCK PRICES - quoteDateTimeIn5MinInc: ', quoteDateTimeIn5MinInc);
            // console.log('STROKE STOCK PRICES - quoteDate: ', new Date(quoteDateTimeIn5MinInc * 300 * 1000));
            // console.log('STROKE STOCK PRICES - stockPrice: ', stockPrice);
            // console.log('STROKE STOCK PRICES - x: ', x);
            // console.log('STROKE STOCK PRICES - y: ', y);

            // context.moveTo(x, y);
            // context.lineTo(x + 1, y);
            // context.lineTo(x + 1, y + 1);
            // context.lineTo(x, y + 1);
            // context.lineTo(x, y);
            context.strokeStyle = '#3e4444'
            context.fillText('.', x - 2.5, y + 1.5);
            // if (quoteDateTimeIn5MinInc === maxQuoteDateTimeIn5MinInc) {
            //     console.log('updateCanvas HERE');
            //     context.fillText('HERE', x - 0.5, y - 0.5);
            //     console.log('updateCanvas x: ', x);
            //     console.log('updateCanvas y: ', y);
            // }
            context.stroke();   // now: stroke() insted of fill()
            context.closePath();
        })
        this.setState({ xMap: xMap })
        this.setState({ yMap: yMap })
        console.log('xMap: ', xMap);
        const menuContext = this.stockMenu.current.getContext('2d');
        menuContext.clearRect(0, 0, 30, 600); // maybe want to put this width and height into state
        menuContext.font = "15px Arial";
        ////const optionMenuContext = this.refs.optionMenu.getContext('2d');
        //context.strokeStyle = 'rgba(255,0,0,0.5)';
        //context.setLineDash([2, 10]);
        const context6 = this.canvas6.current.getContext('2d');
        context6.clearRect(0, 0, canvasWidth, canvasHeight);
        context6.strokeStyle = 'rgba(255,0,0,0.4)'; //  or context6.strokeStyle = '#3e4444';
        context6.font = "15px Arial";
        context6.textAlign = 'center';
        context6.setLineDash([1, 1]);
        context6.lineWidth = 1;
        //PRINT THE STOCK PRICES MENU
        for (let i = Math.ceil(minStockPrice); i <= Math.round(maxStockPrice); i = i + 1) {
            let y = canvasHeight - ((i - minStockPrice) * underlyingLastRatio);
            menuContext.fillText(i, 10, y + 4.5);
            if ((Math.round(i) === (i))) {
                context6.beginPath();
                context6.moveTo(0.5, y - 0.5);
                context6.lineTo(canvasWidth + 0.5, y - 0.5);
                context6.stroke();
                context6.closePath();
            }
        }

        // STROKE THE QUOTEDATETIMES
        // const context6 = this.canvas6.current.getContext('2d');
        // context6.clearRect(0, 0, canvasWidth, canvasHeight);
        // context6.strokeStyle = 'rgba(255,0,0,0.4)'; //  or context6.strokeStyle = '#3e4444';
        // context6.font = "15px Arial";
        // context6.textAlign = 'center';
        let latestMonth = -1;
        let latestDayOfMonth = -1;
        xMapReverse.forEach((x, quoteDateTime5MinInc) => { // MIGHT WANT TO GO FROM minQuoteDateTimeIn5MinInc to maxQuoteDateTimeIn5MinInc AND round per day (or week)
            //console.log('Canvas updateCanvas xMapReverse x: ', x);
            //console.log('Canvas updateCanvas xMapReverse quoteDateTime5MinInc: ', quoteDateTime5MinInc);
            let thisQdt = quoteDateTime5MinInc * 300;
            let thisDateObject = new Date(thisQdt * 1000);
            let thisMonth = thisDateObject.getMonth() + 1;
            let thisDayOfMonth = thisDateObject.getDate();
            let thisDayOfWeek = thisDateObject.getDay(); // starts with 0
            let isMonday = thisDayOfWeek === 1 ? true : false;
            if (latestMonth === -1) {
                latestMonth = thisMonth;
                latestDayOfMonth = thisDayOfMonth;
            } else {
                if (latestDayOfMonth !== thisDayOfMonth) {
                    let text = thisMonth.toString() + '/' + thisDayOfMonth.toString();
                    console.log('Canvas updateCanvas text: ', text);
                    context6.fillStyle = "#a4252c";
                    if (isMonday) { // Prints only on Monday - can change
                        context6.fillText(text, x - 1.5, canvasHeight - 20);
                    }
                    context6.beginPath();
                    context6.moveTo(x - 0.5, 0);
                    context6.lineTo(x - 0.5, canvasHeight - 40);
                    context6.stroke();
                    context6.closePath();
                    latestMonth = thisMonth;
                    latestDayOfMonth = thisDayOfMonth;
                }
            }
        })





        //let maxOptionPrice = -1;
        //let minOptionPrice = 9999999;

        // console.log('Canvas this.props.quotes: ', this.props.quotes);
        // console.log('Canvas this.state.optionSymbolsToChart: ', this.state.optionSymbolsToChart);
        // let optionQuotesChartMap = new Map();
        // if (arrayOfSymbolsToChart) { // checking for maxOptionPrice
        //     arrayOfSymbolsToChart.map((symbol) => {
        //         let thisOptionSymbolChartedQuotes = new Array();
        //         this.props.quotes.map((quote) => {
        //             if (quote.optionSymbol == symbol) {
        //                 let quoteObject = {
        //                     quoteDateTime: quote.quoteDateTime,
        //                     optionSymbol: quote.optionSymbol,
        //                     bid: quote.bid,
        //                     ask: quote.ask,
        //                     impliedVol: quote.impliedVol,
        //                     underlyingLast: quote.underlyingLast,
        //                     bestOptionForQDT: quote.bestOptionForQDT
        //                 }
        //                 //console.log('thisOptionSymbolChartedQuotes.push(quoteObject); ', quoteObject);
        //                 thisOptionSymbolChartedQuotes.push(quoteObject);
        //                 if (quote.ask > maxOptionPrice) {
        //                     maxOptionPrice = quote.ask;
        //                 }
        //             }
        //         })
        //         optionQuotesChartMap.set(symbol, thisOptionSymbolChartedQuotes);
        //     })
        // }
        // this.setState({ optionQuotesChartMap: optionQuotesChartMap });
        // console.log('Canvas maxOptionPrice: ', maxOptionPrice);
        // //console.log('Canvas optionQuotesChartArray: ', optionQuotesChartArray);
        // console.log('optionQuotesChartMap: ', optionQuotesChartMap);
        // context.strokeStyle = 'rgba(0,255,0,0.5)';
        // callContext.strokeStyle = '#82b74b';
        // putContext.strokeStyle = '#f18973';
        // context.beginPath(); // stroke the option prices
        // let optionPriceRatio = canvasHeight / maxOptionPrice;
        // optionQuotesChartMap.forEach((value, optionSymbol) => {
        //     console.log('optionSymbol: ', optionSymbol);
        //     let putOrCallLetter = optionSymbol.substr(optionSymbol.indexOf("2") + 6, 1);
        //     console.log('putOrCallLetter: ', putOrCallLetter);
        //     console.log('value: ', value);
        //     if (putOrCallLetter === 'C') {
        //         context.strokeStyle = '#82b74b';
        //     } else {
        //         context.strokeStyle = '#f18973';
        //     }
        //     value.forEach((quote, index) => {
        //         //console.log('quote.quoteDateTime: ', quote.quoteDateTime);
        //         //console.log('quoteDateTimeRatio: ', quoteDateTimeRatio);
        //         //let x = ((quote.quoteDateTime - minQuoteDateTime) * quoteDateTimeRatio);
        //         let x = Math.round(quoteDateTimeMap.get(quote.quoteDateTime) * quoteDateTimeRatio);
        //         let bidY = Math.round(canvasHeight - (quote.bid * optionPriceRatio));
        //         let askY = Math.round(canvasHeight - (quote.ask * optionPriceRatio));
        //         if (putOrCallLetter === 'C') {
        //             context.beginPath();
        //             context.moveTo(x, bidY);
        //             context.lineTo(x, askY);
        //             context.stroke();   // now: stroke() insted of fill()
        //             context.closePath();
        //         } else {
        //             putContext.beginPath();
        //             putContext.moveTo(x, bidY);
        //             putContext.lineTo(x, askY);
        //             putContext.stroke();   // now: stroke() insted of fill()
        //             putContext.closePath();
        //         }
        //     })

        // })
        // // print the option prices menu
        // optionMenuContext.clearRect(0, 0, 50, 500); // this canvas width can height can be put in state?
        // for (let i = 0; i <= Math.round(maxOptionPrice); i = i + 1) {
        //     optionMenuContext.font = "15px Arial";
        //     let y = canvasHeight - (i * optionPriceRatio);
        //     optionMenuContext.fillText(i, 10, y);
        //     // if ((Math.round(i / 0.05) == (i / 0.05))) {
        //     //     optionMenuContext.moveTo(0, y);
        //     //     optionMenuContext.lineTo(50, y);
        //     //     optionMenuContext.stroke();
        //     //     optionMenuContext.closePath();
        //     // }
        // }


        //this.forceUpdate();
        //console.log(quote.quoteDateTime, quote.underlyingLast);
    }

    // onScroll(e) {
    //     console.log('onScroll e: ', e);
    //     var el = document.getElementById('item2');
    //     console.log('el: ', el);
    //     console.log('el.scrollLeft: ', el.scrollLeft);
    //     //console.log('e.target.body.scrollTop: ', e.target.body.scrollTop);
    //     console.log('e.target: ', e.target);
    //     console.log('e.currentTarget: ', e.currentTarget);
    //     console.log('e.target.detail: ', e.target.detail);
    //     console.log('e.target.view: ', e.target.view);
    //     console.log('document.body.scrollLeft: ', document.body.scrollLeft);
    // }

    onClick(e) {
        this.setState({ canvasMouseMoveChangesEnabled: !this.state.canvasMouseMoveChangesEnabled });
    }

    handleOnMouseMove(e) {
        //console.log('this.state.optionSymbolsToChart: ', this.state.optionSymbolsToChart);
        //console.log('this.state.optionQuotesChartMap: ', this.state.optionQuotesChartMap);
        //console.log("onMouseOver");
        if (this.state.canvasMouseMoveChangesEnabled) {
            let scrollLeft = Math.round(this.item2.current.scrollLeft);
            let x = e.clientX - 65 + scrollLeft;
            //let x = e.pageX - 65;
            //console.log('e: ', e);
            //let x = e.clientX - 65;
            //let y = e.clientY;
            let y = e.pageY - 89;
            let canvasHeight = this.state.canvasHeight;
            console.log('handleOnMouseMove canvasHeight: ', canvasHeight);
            let canvasWidth = this.state.canvasWidth;
            console.log('handleOnMouseMove canvasWidth: ', canvasWidth);
            console.log('handleOnMouseMove this.state.quoteDateTimeRatio: ', this.state.quoteDateTimeRatio);
            //let minStockPrice = this.state.minStockPrice;
            //let underlyingLastRatio = this.state.underlyingLastRatio;
            //let mapXValue = Math.round(x / this.state.quoteDateTimeRatio);
            if (this.state.xMap.get !== undefined) {
                let xMapQuoteDateTimeIn5MinInc = this.state.xMap.get(x);
                console.log('handleOnMouseMove xMapQuoteDateTimeIn5MinInc: ', xMapQuoteDateTimeIn5MinInc);
                //this.setState({ xMapQuoteDateTimeIn5MinIncOnMouseMove: xMapQuoteDateTimeIn5MinInc });
                //console.log('this.props.stockQuotesMap.length: ', this.props.stockQuotesMap.length);
                //console.log('this.props.stockQuotesMap.size: ', this.props.stockQuotesMap.size);
                let xMapFoundStockPrice = this.props.stockQuotesMap.get(xMapQuoteDateTimeIn5MinInc);
                console.log('handleOnMouseMove xMapFoundStockPrice: ', xMapFoundStockPrice);
                let thisY = this.state.yMap.get(x);
                let thisDate = new Date(xMapQuoteDateTimeIn5MinInc * 300 * 1000);
                console.log('handleOnMouseMove thisDate: ', thisDate);
                let minute = thisDate.getMinutes();
                if (minute < 10) {
                    minute = '0' + minute;
                }
                let formattedDate = thisDate.getFullYear() + '-' + (thisDate.getMonth() + 1) + '-' + thisDate.getDate() + ' ' + thisDate.getHours() + ':' + minute;
                this.setState({ formattedDate: formattedDate });
                console.log('handleOnMouseMove formattedDate: ', formattedDate);
                const context2 = this.canvas2.current.getContext('2d');
                context2.clearRect(0, 0, canvasWidth, canvasHeight);
                context2.strokeStyle = 'rgba(255,0,0,0.5)';
                //context2.fillStyle = "#a4252c";
                context2.fillStyle = 'black';
                context2.font = '15px Arial';
                if (x < 70) {
                    context2.textAlign = 'left';
                } else if (x > 330) {
                    context2.textAlign = 'right';
                } else {
                    context2.textAlign = 'center';
                }
                let stockPriceText = 'Stock Price: ' + xMapFoundStockPrice;
                let stockSymbolText = 'Stock Symbol: ' + this.props.stockSymbol
                let companyNameText = this.props.stockInfo.name;
                let exchAndNumOptions = this.props.stockInfo.exch + ' - has ' + this.props.stockInfo.numberOfOptions + ' Options';
                if (formattedDate !== 'NaN-NaN-NaN NaN:NaN') {
                    context2.fillText(companyNameText, x, y - 100);
                    context2.fillText(exchAndNumOptions, x, y - 80);
                    context2.fillText(stockSymbolText, x, y - 60);
                    context2.fillText(formattedDate, x, y - 40);
                    context2.fillText(stockPriceText, x, y - 20);
                }
                console.log('Canvas handleOnMouseMove this.props: ', this.props);


                console.log('handleOnMouseMove this.state.callsMap.length: ', this.state.callsMap.length);
                if (this.state.callsMap.length !== 0) {
                    let calls = this.state.callsMap.get(xMapQuoteDateTimeIn5MinInc);
                    let puts = this.state.putsMap.get(xMapQuoteDateTimeIn5MinInc);
                    console.log('handleOnMouseMove this.props.stockQuotesMap: ', this.props.stockQuotesMap);
                    console.log('handleOnMouseMove this.state.callsMap: ', this.state.callsMap);
                    console.log('handleOnMouseMove this.state.putsMap: ', this.state.putsMap);
                    console.log('handleOnMouseMove calls: ', calls);
                    console.log('handleOnMouseMove puts: ', puts);

                    //let callStrikeArray = [...this.state.callStrikeSet];
                    //let putStrikeArray = [...this.state.putStrikeSet];
                    //let displayCallsMap = new Map();
                    //let displayPutsMap = new Map();
                    //callStrikeArray.map((strike) => displayCallsMap.set(strike, {}));
                    //putStrikeArray.map((strike) => displayPutsMap.set(strike, {}));
                    let callsMap = [];
                    let putsMap = [];
                    if (calls) {
                        //let callStrikeArray = [...this.state.callStrikeSet];
                        callsMap = new Map();
                        this.state.callStrikeArray.map((strike) => callsMap.set(strike, {}));
                        //let callsMap = this.state.displayCallsMap;           
                        calls.forEach((value, strike) => {
                            value.qdt5Mins = xMapQuoteDateTimeIn5MinInc
                            callsMap.set(strike, value)
                        })
                        this.setState({ displayCallsMap: callsMap })
                        this.setState({ current5MinQdt: xMapQuoteDateTimeIn5MinInc });
                    }
                    if (puts) {
                        //let putStrikeArray = [...this.state.putStrikeSet];
                        //let putsMap = this.state.displayPutsMap;
                        putsMap = new Map();
                        this.state.putStrikeArray.map((strike) => putsMap.set(strike, {}));
                        puts.forEach((value, strike) => {
                            value.gdt5Mins = xMapQuoteDateTimeIn5MinInc
                            putsMap.set(strike, value)
                        })
                        this.setState({ displayPutsMap: putsMap })
                        this.setState({ current5MinQdt: xMapQuoteDateTimeIn5MinInc });
                    }

                    console.log('handleOnMouseMove displayCallsMap: ', callsMap);
                    console.log('handleOnMouseMove displayPutsMap: ', putsMap);
                }

                //let mapXValue = x;
                //console.log('mapXValue: ', mapXValue);
                //let quoteDateTimeIn5MinInc = Math.round(mapXValue + this.state.minQuoteDateTimeIn5MinInc);
                //console.log('quoteDateTimeIn5MinInc: ', quoteDateTimeIn5MinInc);
                //console.log('this.props.stockQuotesMap: ', this.props.stockQuotesMap);
                //console.log('this.state.stockQuoteMap.size: ', this.state.stockQuoteMap.size);
                //console.log('this.state.stockQuoteMap: ', this.state.stockQuoteMap);
                //console.log('this.state.quoteDateTimeMap: ', this.state.quoteDateTimeMap);
                //console.log('this.state.reverseQuoteDateTimeMap: ', this.state.reverseQuoteDateTimeMap);
                //let reverseQuoteDateTimeMapQuoteDateTimeIn5MinInc = this.state.reverseQuoteDateTimeMap.get(mapXValue);
                ////let thisQuoteDateTimeDiv300 = Math.round(reverseQuoteDateTimeMapQuoteDateTime / 300);
                //let thisStockPrice = this.state.stockQuoteMap.get(thisQuoteDateTimeDiv300);
                //let thisStockPrice = this.props.stockQuotesMap.get(thisQuoteDateTimeDiv300);
                //let thisStockPrice = this.props.stockQuotesMap.get(quoteDateTimeIn5MinInc);
                //console.log('thisStockPrice: ', thisStockPrice);
                //let thisQuoteDateTime = reverseQuoteDateTimeMapQuoteDateTimeIn5MinInc * 300;
                //let thisDate = new Date(thisQuoteDateTime * 1000);




                //let optionQuotesChartMap = this.state.optionQuotesChartMap;
                //console.log('Canvas handleOnMouseMove optionQuotesChartMap: ', optionQuotesChartMap);
                ////let optionQuoteText = '';
                ////let callQuoteText = '';
                ////let putQuoteText = '';
                ////let bestOptionForQDTText = '';
                // optionQuotesChartMap.forEach((value, optionSymbol) => {
                //     let putOrCallLetter = optionSymbol.substr(optionSymbol.indexOf("2") + 6, 1);
                //     value.map((quote) => {
                //         if (quote.quoteDateTime / 300 == thisQuoteDateTimeDiv300) {
                //             let bestQuoteArray = this.props.quotes.filter((thisQuote) => thisQuote.quoteDateTime === quote.quoteDateTime).filter((anotherQuote) => anotherQuote.optionSymbol === quote.bestOptionForQDT);
                //             let bestQuote = bestQuoteArray[0];
                //             if (putOrCallLetter === 'C') {
                //                 //console.log('this is a call');
                //                 callQuoteText += quote.optionSymbol + ' bid: ' + quote.bid + ' ask: ' + quote.ask + ' impliedVol: ' + quote.impliedVol;
                //                 bestOptionForQDTText = bestQuote.optionSymbol + ' bid: ' + bestQuote.bid + ' ask: ' + bestQuote.ask + ' impliedVol: ' + bestQuote.impliedVol + ' maxBidPctInc: ' + bestQuote.maxBidPctInc;
                //             } else {
                //                 //console.log('this is a put');
                //                 putQuoteText += quote.optionSymbol + ' bid: ' + quote.bid + ' ask: ' + quote.ask + ' impliedVol: ' + quote.impliedVol;
                //                 bestOptionForQDTText = bestQuote.optionSymbol + ' bid: ' + bestQuote.bid + ' ask: ' + bestQuote.ask + ' impliedVol: ' + bestQuote.impliedVol + ' maxBidPctInc: ' + bestQuote.maxBidPctInc;
                //             }
                //         }
                //     })

                //     //console.log('this.state.quoteDateTimeMap.size(): ', this.state.quoteDateTimeMap.size());
                //const context = this.refs.canvas2.getContext('2d');
                //context.clearRect(0, 0, canvasWidth, canvasHeight);
                context2.strokeStyle = 'rgba(255,0,0,0.5)';          // now: strokeStyle instead of fillStyle
                // the same path again as before:
                context2.beginPath(); // stroke the moving vertical
                context2.moveTo(x, 0);
                context2.lineTo(x, canvasHeight);
                context2.stroke();                                 // now: stroke() insted of fill()
                context2.closePath();
                //let thisY = canvasHeight - ((thisStockPrice - minStockPrice) * underlyingLastRatio);
                context2.beginPath(); // stroke the moving horizontal
                context2.moveTo(0, thisY);
                context2.lineTo(canvasWidth, thisY);
                context2.stroke();                                 // now: stroke() insted of fill()
                context2.closePath();
                //     //console.log('this.state.stockQuotes: ', this.state.stockQuotes);
                //     // this.state.stockQuotes.map((quote) => {
                //     //     let underlyingLast = quote.underlyingLast;
                //     //     let quoteDateTime = quote.quoteDateTime;
                //     // })
                //     context.font = "15px Arial";
                //     //let y = canvasHeight - (i * optionPriceRatio);
                //     // context.fillStyle = '#FFF';
                //     // context.fillRect(x, thisY - 40, 100, 40);
                //     context.fillStyle = '#000000';
                //     if (thisStockPrice) {
                //         // if (x / canvasWidth < 0.3) {
                //         //     let stockPriceText = 'Stock Price: ' + thisStockPrice.toString();
                //         //     context.fillText(stockPriceText, x + 5, thisY - 20);
                //         //     context.fillText(callQuoteText, x + 5, thisY);
                //         //     context.fillText(putQuoteText, x + 5, thisY + 20);
                //         //     context.fillText(thisDate, x + 5, thisY + 40);
                //         // } else if (x / canvasWidth < 0.8) {
                //         let stockPriceText = 'Stock Price: ' + thisStockPrice;
                //         context.fillText(stockPriceText, 0, 250);
                //         context.fillText(callQuoteText, 0, 270);
                //         context.fillText(putQuoteText, 0, 290);
                //         context.fillText(bestOptionForQDTText, 0, 310);
                //         context.fillText(thisDate, 0, 330);
                //         // } else {
                //         //     let stockPriceText = 'Stock Price: ' + thisStockPrice;
                //         //     context.fillText(stockPriceText, x - 350, thisY - 20);
                //         //     context.fillText(callQuoteText, x - 350, thisY);
                //         //     context.fillText(putQuoteText, x - 350, thisY + 20);
                //         //     context.fillText(thisDate, x - 350, thisY + 40);
                //         // }
                //     }



                //     console.log('this.state.expirationDateQuotesMap: ', this.state.expirationDateQuotesMap);
                // })
            }
        }
    }

    async getOptionQuotes(stockSymbol, expDate) {
        let stockQuoteDateTime5MinsArray = [...this.props.stockQuotesMap.keys()];
        const res = await axios.get('https://securedoug.com/stockreact/optionsymbols/' + stockSymbol + '/' + expDate);
        let data = res.data;
        console.log('Canvas getOptionQuotes data: ', data);
        // let year = expDate.substr(0, 2);
        // let month = expDate.substr(2, 2);
        // let day = expDate.substr(4, 2);
        // year = (Number(year) + 2000).toString();
        // //year = '2001';
        // let expDateObject = new Date(year, month - 1, day, 18, 0, 0);
        let expDateObject = new Date(2000 + Number(expDate.substr(0, 2)), Number(expDate.substr(2, 2)) - 1, Number(expDate.substr(4, 2)), 20);
        console.log('expDateObject: ', expDateObject);
        let expDateUnixTime = expDateObject.getTime() / 1000;
        console.log('expDateUnixTime: ', expDateUnixTime);
        let expDateUnixTimeDiv300 = Math.round(expDateUnixTime / 300);
        console.log('expDateUnixTimeDiv300: ', expDateUnixTimeDiv300);
        //let anotherDate = new Date(1000000000000);
        //console.log('anotherDate: ', anotherDate);
        let maxStockPrice = this.state.maxStockPrice;
        let minStockPrice = this.state.minStockPrice;
        let callsMap = new Map();
        let putsMap = new Map();
        let callsMapByStrike = new Map();
        let putsMapByStrike = new Map();
        let maxCallStrikePrice = 0;
        let minCallStrikePrice = 9999999;
        let maxPutStrikePrice = 0;
        let minPutStrikePrice = 9999999;
        //let callStrikeInc = 0;
        //let putStrikeInc = 0;
        let callStrikeSet = new Set();
        let putStrikeSet = new Set();
        let quoteDateTimeIn5MinIncSet = new Set();
        //let countsWhereQDTIn5MinsIncNotInStockQuotesMap = 0;
        //let quotesWhereQDTIn5MinsIncNotInStockQuotesMapArray = new Array();
        let callsEntriesAdded = 0;
        let callsRegularEntriesAdded = 0;
        let putsEntriesAdded = 0;
        let putsRegularEntriesAdded = 0;
        for (let i in data) {
            //console.log('Canvas getOptionQuotes i: ', i);
            //console.log('Canvas getOptionQuotes data[0]: ', data[0]);
            let optionSymbol = data[i].optionSymbol;
            //console.log('Canvas getOptionQuotes optionSymbol: ', optionSymbol);
            let putOrCall = data[i].putOrCall;
            let strikePrice = data[i].strikePrice;
            //console.log('Canvas getOptionQuotes putOrCall: ', putOrCall);
            //console.log('Canvas getOptionQuotes strikePrice: ', strikePrice);
            let getThisOptionSymbolsData = false;
            if (putOrCall === 'Call') {
                if (strikePrice > minStockPrice) {
                    //let pctOutofMax = (strikePrice / maxStockPrice) - 1;
                    //console.log('maxStockPrice: ', maxStockPrice);
                    //console.log('Call pctOutofMax: ', pctOutofMax);
                    getThisOptionSymbolsData = true;
                }
            } else {
                if (strikePrice < maxStockPrice) {
                    //let pctOutOfMin = (strikePrice / minStockPrice) - 1;
                    //console.log('minStockPrice: ', minStockPrice);
                    //console.log('Put pctOutOfMin: ', pctOutOfMin);
                    getThisOptionSymbolsData = true;
                }
            }
            if (getThisOptionSymbolsData) {
                const res2 = await axios.get('https://securedoug.com/stockreact/optionquotes/' + optionSymbol);

                let thisMap = new Map();
                res2.data.map((quote) => thisMap.set(Math.round(quote.quoteDateTime / 300), quote));
                //console.log('thisMap: ', thisMap);
                let thisQuoteDateTime5MinArray = [...thisMap.keys()];
                ////let thisMaxQDT5Min = Math.max(...thisQuoteDateTime5MinArray);
                ////let thisMinQDT5Min = Math.min(...thisQuoteDateTime5MinArray);
                //console.log('thisQuoteDateTime5MinArray.length', thisQuoteDateTime5MinArray.length);
                //console.log('stockQuoteDateTime5MinsArray.length', stockQuoteDateTime5MinsArray.length);
                let qdt5MinsWithoutQuotesArray = stockQuoteDateTime5MinsArray.filter(stockQDT5Min => ((thisQuoteDateTime5MinArray.find(element => (element === stockQDT5Min)) === undefined) && (stockQDT5Min < expDateUnixTimeDiv300)));
                // console.log('qdt5MinsWithoutQuotesArray: ', qdt5MinsWithoutQuotes);
                // console.log('qdt5MinsWithoutQuotesArray[0]: ', qdt5MinsWithoutQuotes[0]);
                // console.log('qdt5MinsWithoutQuotesArray[1]: ', qdt5MinsWithoutQuotes[1]);
                let latestIndexFromqdt5MinsWithoutQuotes = 0;
                let previousQDTIn5MinsInc = 0;
                let thisObject = {};
                let previousObject = {};
                res2.data.forEach((quote) => {
                    //latestIndexFromqdt5MinsWithoutQuotes++;
                    let latestQDT5MinsWithoutQuotes = qdt5MinsWithoutQuotesArray[latestIndexFromqdt5MinsWithoutQuotes];
                    //console.log('Canvas getOptionQuotes quote: ', quote);
                    let quoteDateTimeIn5MinInc = Math.round(quote.quoteDateTime / 300);
                    quoteDateTimeIn5MinIncSet.add(quoteDateTimeIn5MinInc);
                    let max5MinForCopyingQuotes = 4; // MIGHT WANT TO ADJUST THIS - USING 30 MINS FOR MAX REPEAT
                    //console.log('------------------------------------');
                    //console.log('quoteDateTimeIn5MinInc: ', quoteDateTimeIn5MinInc);
                    //console.log('latestQDT5MinsWithoutQuotes: ', latestQDT5MinsWithoutQuotes);
                    //console.log('quoteDateTimeIn5MinInc - latestQDT5MinsWithoutQuotes: ', quoteDateTimeIn5MinInc - latestQDT5MinsWithoutQuotes);
                    if ((latestQDT5MinsWithoutQuotes < quoteDateTimeIn5MinInc) && (previousQDTIn5MinsInc !== 0)) {
                        while ((latestQDT5MinsWithoutQuotes < quoteDateTimeIn5MinInc) && (previousQDTIn5MinsInc !== 0)) {
                            if ((quoteDateTimeIn5MinInc - latestQDT5MinsWithoutQuotes) < max5MinForCopyingQuotes) {
                                if (putOrCall === 'Call') {
                                    if (callsMap.has(latestQDT5MinsWithoutQuotes)) {
                                        //console.log('adding entry for call without quote');
                                        //let newMap = new Map();
                                        //newMap.set(strikePrice, previousObject);
                                        //console.log('strikePrice: ', strikePrice, ' previousObject: ', previousObject);
                                        callsMap.get(latestQDT5MinsWithoutQuotes).set(strikePrice, previousObject);
                                        //console.log('callsMap.size: ', callsMap.size);
                                        if (callsMapByStrike.has(strikePrice)) {
                                            callsMapByStrike.get(strikePrice).set(latestQDT5MinsWithoutQuotes, previousObject);
                                        } else {
                                            let thisNewMap = new Map();
                                            thisNewMap.set(latestQDT5MinsWithoutQuotes, previousObject);
                                            callsMapByStrike.set(strikePrice, thisNewMap);
                                        }
                                        latestIndexFromqdt5MinsWithoutQuotes++;
                                        latestQDT5MinsWithoutQuotes = qdt5MinsWithoutQuotesArray[latestIndexFromqdt5MinsWithoutQuotes];
                                        callsEntriesAdded++
                                    } else {
                                        //console.log('adding entry for call without quote');
                                        let newMap = new Map();
                                        newMap.set(strikePrice, previousObject);
                                        //console.log('strikePrice: ', strikePrice, ' previousObject: ', previousObject);
                                        callsMap.set(latestQDT5MinsWithoutQuotes, newMap);
                                        //console.log('callsMap.size: ', callsMap.size);
                                        if (callsMapByStrike.has(strikePrice)) {
                                            callsMapByStrike.get(strikePrice).set(latestQDT5MinsWithoutQuotes, previousObject);
                                        } else {
                                            let thisNewMap = new Map();
                                            thisNewMap.set(latestQDT5MinsWithoutQuotes, previousObject);
                                            callsMapByStrike.set(strikePrice, thisNewMap);
                                        }
                                        latestIndexFromqdt5MinsWithoutQuotes++;
                                        latestQDT5MinsWithoutQuotes = qdt5MinsWithoutQuotesArray[latestIndexFromqdt5MinsWithoutQuotes];
                                        callsEntriesAdded++
                                    }
                                } else {
                                    if (putsMap.has(latestQDT5MinsWithoutQuotes)) {
                                        //console.log('adding entry for call without quote');
                                        //let newMap = new Map();
                                        //newMap.set(strikePrice, previousObject);
                                        //console.log('strikePrice: ', strikePrice, ' previousObject: ', previousObject);
                                        putsMap.get(latestQDT5MinsWithoutQuotes).set(strikePrice, previousObject);
                                        //console.log('callsMap.size: ', callsMap.size);
                                        if (putsMapByStrike.has(strikePrice)) {
                                            putsMapByStrike.get(strikePrice).set(latestQDT5MinsWithoutQuotes, previousObject);
                                        } else {
                                            let thisNewMap = new Map();
                                            thisNewMap.set(latestQDT5MinsWithoutQuotes, previousObject);
                                            putsMapByStrike.set(strikePrice, thisNewMap);
                                        }
                                        latestIndexFromqdt5MinsWithoutQuotes++;
                                        latestQDT5MinsWithoutQuotes = qdt5MinsWithoutQuotesArray[latestIndexFromqdt5MinsWithoutQuotes];
                                        callsEntriesAdded++
                                    } else {
                                        //console.log('adding entry for call without quote');
                                        let newMap = new Map();
                                        newMap.set(strikePrice, previousObject);
                                        //console.log('strikePrice: ', strikePrice, ' previousObject: ', previousObject);
                                        putsMap.set(latestQDT5MinsWithoutQuotes, newMap);
                                        //console.log('callsMap.size: ', callsMap.size);
                                        if (putsMapByStrike.has(strikePrice)) {
                                            putsMapByStrike.get(strikePrice).set(latestQDT5MinsWithoutQuotes, previousObject);
                                        } else {
                                            let thisNewMap = new Map();
                                            thisNewMap.set(latestQDT5MinsWithoutQuotes, previousObject);
                                            putsMapByStrike.set(strikePrice, thisNewMap);
                                        }
                                        latestIndexFromqdt5MinsWithoutQuotes++;
                                        latestQDT5MinsWithoutQuotes = qdt5MinsWithoutQuotesArray[latestIndexFromqdt5MinsWithoutQuotes];
                                        putsEntriesAdded++
                                    }
                                }
                            } else {
                                //console.log('qdts without quotes too far behind');
                                latestIndexFromqdt5MinsWithoutQuotes++;
                                latestQDT5MinsWithoutQuotes = qdt5MinsWithoutQuotesArray[latestIndexFromqdt5MinsWithoutQuotes];
                            }
                        }
                    } else {
                        //
                    }
                    if (putOrCall === 'Call') {
                        callStrikeSet.add(strikePrice);
                        maxCallStrikePrice = strikePrice > maxCallStrikePrice ? strikePrice : maxCallStrikePrice;
                        minCallStrikePrice = strikePrice < minCallStrikePrice ? strikePrice : minCallStrikePrice;
                        if (callsMap.has(quoteDateTimeIn5MinInc)) {
                            // this object gets put into both callsMap and callsMapByStrike
                            // later the maxBidPctInc, and maxBid, and maxBidQdt will be added
                            // and returned to callsMapByStrike - but it also ends up in callsMap
                            // quite a convenient shallow copy it would seem
                            thisObject = {
                                qdt5Mins: quoteDateTimeIn5MinInc,
                                bid: quote.bid,
                                ask: quote.ask,
                                vol: quote.volume,
                                openInt: quote.openInt,
                                impliedVol: quote.impliedVol
                            }
                            callsMap.get(quoteDateTimeIn5MinInc).set(strikePrice, thisObject);
                            if (callsMapByStrike.has(strikePrice)) {
                                callsMapByStrike.get(strikePrice).set(latestQDT5MinsWithoutQuotes, thisObject);
                            } else {
                                let thisNewMap = new Map();
                                thisNewMap.set(quoteDateTimeIn5MinInc, thisObject);
                                callsMapByStrike.set(strikePrice, thisNewMap);
                            }
                            callsRegularEntriesAdded++;
                        } else {
                            let newMap = new Map();
                            // this object gets put into both callsMap and callsMapByStrike
                            // later the maxBidPctInc, and maxBid, and maxBidQdt will be added
                            // and returned to callsMapByStrike - but it also ends up in callsMap
                            // quite a convenient shallow copy it would seem
                            thisObject = {
                                qdt5Mins: quoteDateTimeIn5MinInc,
                                bid: quote.bid,
                                ask: quote.ask,
                                vol: quote.volume,
                                openInt: quote.openInt,
                                impliedVol: quote.impliedVol
                            }
                            newMap.set(strikePrice, thisObject);
                            callsMap.set(quoteDateTimeIn5MinInc, newMap);
                            if (callsMapByStrike.has(strikePrice)) {
                                callsMapByStrike.get(strikePrice).set(latestQDT5MinsWithoutQuotes, thisObject);
                            } else {
                                let thisNewMap = new Map();
                                thisNewMap.set(quoteDateTimeIn5MinInc, thisObject);
                                callsMapByStrike.set(strikePrice, thisNewMap);
                            }
                            callsRegularEntriesAdded++;
                        }
                    } else {
                        putStrikeSet.add(strikePrice);
                        maxPutStrikePrice = strikePrice > maxPutStrikePrice ? strikePrice : maxPutStrikePrice;
                        minPutStrikePrice = strikePrice < minPutStrikePrice ? strikePrice : minPutStrikePrice;
                        if (putsMap.has(quoteDateTimeIn5MinInc)) {
                            // this object gets put into both putsMap and putsMapByStrike
                            // later the maxBidPctInc, and maxBid, and maxBidQdt will be added
                            // and returned to putsMapByStrike - but it also ends up in putsMap
                            // quite a convenient shallow copy it would seem
                            thisObject = {
                                qdt5Mins: quoteDateTimeIn5MinInc,
                                bid: quote.bid,
                                ask: quote.ask,
                                vol: quote.volume,
                                openInt: quote.openInt,
                                impliedVol: quote.impliedVol
                            }
                            putsMap.get(quoteDateTimeIn5MinInc).set(strikePrice, thisObject);
                            if (putsMapByStrike.has(strikePrice)) {
                                putsMapByStrike.get(strikePrice).set(latestQDT5MinsWithoutQuotes, thisObject);
                            } else {
                                let thisNewMap = new Map();
                                thisNewMap.set(quoteDateTimeIn5MinInc, thisObject);
                                putsMapByStrike.set(strikePrice, thisNewMap);
                            }
                            putsRegularEntriesAdded++;
                        } else {
                            let newMap = new Map();
                            // this object gets put into both putsMap and putsMapByStrike
                            // later the maxBidPctInc, and maxBid, and maxBidQdt will be added
                            // and returned to putsMapByStrike - but it also ends up in putsMap
                            // quite a convenient shallow copy it would seem
                            thisObject = {
                                qdt5Mins: quoteDateTimeIn5MinInc,
                                bid: quote.bid,
                                ask: quote.ask,
                                vol: quote.volume,
                                openInt: quote.openInt,
                                impliedVol: quote.impliedVol
                            }
                            newMap.set(strikePrice, thisObject);
                            putsMap.set(quoteDateTimeIn5MinInc, newMap);
                            if (putsMapByStrike.has(strikePrice)) {
                                putsMapByStrike.get(strikePrice).set(latestQDT5MinsWithoutQuotes, thisObject);
                            } else {
                                let thisNewMap = new Map();
                                thisNewMap.set(quoteDateTimeIn5MinInc, thisObject);
                                putsMapByStrike.set(strikePrice, thisNewMap);
                            }
                            putsRegularEntriesAdded++;
                        }
                    }
                    previousQDTIn5MinsInc = quoteDateTimeIn5MinInc;
                    previousObject = thisObject;
                })
            }
        }
        console.log('Canvas getOptionQuotes callsEntriesAdded: ', callsEntriesAdded);
        console.log('Canvas getOptionQuotes callsRegularEntriesAdded: ', callsRegularEntriesAdded);
        console.log('Canvas getOptionQuotes putsEntriesAdded: ', putsEntriesAdded);
        console.log('Canvas getOptionQuotes putsRegularEntriesAdded: ', putsRegularEntriesAdded);
        console.log('Canvas getOptionQuotes Array.from(callsMap): ', [...callsMap.values()]);
        console.log('Canvas getOptionQuotes putsMap.size: ', putsMap.size);

        // let completeCallsMap = new Map();
        // let completePutsMap = new Map();
        // callsMap.forEach((strikeMap, quoteDateTimeIn5MinInc) => {

        // })

        console.log('callStrikeSet: ', callStrikeSet);
        console.log('putStrikeSet: ', putStrikeSet);
        let callStrikeArray = [...callStrikeSet];
        let putStrikeArray = [...putStrikeSet];
        let displayCallsMap = new Map();
        let displayPutsMap = new Map();
        callStrikeArray.map((strike) => displayCallsMap.set(strike, {}));
        putStrikeArray.map((strike) => displayPutsMap.set(strike, {}));
        console.log('callStrikeArray: ', callStrikeArray);
        console.log('putStrikeArray: ', putStrikeArray);
        // console.log('callStrikeSet: ', callStrikeSet);
        // console.log('putStrikeSet: ', putStrikeSet);
        console.log('callsMap.size: ', callsMap.size);
        console.log('putsMap.size: ', putsMap.size);
        console.log('callsMap: ', callsMap);
        console.log('putsMap: ', putsMap);

        console.log('callsMapByStrike.size: ', callsMapByStrike.size);
        console.log('putsMapByStrike.size: ', putsMapByStrike.size);
        console.log('callsMapByStrike: ', callsMapByStrike);
        console.log('putsMapByStrike: ', putsMapByStrike);
        // thisObject = { // for reference
        //     bid: quote.bid,
        //     ask: quote.ask,
        //     vol: quote.volume,
        //     openInt: quote.openInt,
        //     impliedVol: quote.impliedVol
        // }

        // the object gets put into both callsMap and callsMapByStrike
        // the maxBidPctInc, and maxBid, and maxBidQdt will be added
        // and returned to callsMapByStrike - but it also ends up in callsMap
        // quite a convenient shallow copy it would seem - see the code earlier 
        // when the callsMapByStrike is filled with data
        callsMapByStrike.forEach((qdtMap, strike) => { // IT SEEMS LIKE THE maxBidPctInc and maxBid etc are ending up the the callsMap also - looks like the object is a shallow copy - which seems good in this case
            qdtMap.forEach((object, qdt) => {
                let maxBid = -999999;
                let maxBidQdt = qdt; // start with qdt?
                qdtMap.forEach((object2, qdt2) => {
                    if (qdt2 > qdt) {
                        if (object2.bid > maxBid) {
                            maxBid = object2.bid;
                            maxBidQdt = qdt2;
                        }
                    }
                })
                object.maxBid = maxBid;
                object.maxBidQdt = maxBidQdt;
                object.maxBidPctInc = Number((((maxBid - object.ask) / object.ask) * 100).toFixed(2));
                return object; // also goes back to callsMap
            })
        })
        console.log('2callsMapByStrike: ', callsMapByStrike);
        // the object gets put into both putsMap and putsMapByStrike
        // the maxBidPctInc, and maxBid, and maxBidQdt will be added
        // and returned to putsMapByStrike - but it also ends up in putsMap
        // quite a convenient shallow copy it would seem - see the code earlier 
        // when the putsMapByStrike is filled with data
        putsMapByStrike.forEach((qdtMap, strike) => {
            qdtMap.forEach((object, qdt) => {
                let maxBid = -999999;
                let maxBidQdt = qdt; // start with qdt?
                qdtMap.forEach((object2, qdt2) => {
                    if (qdt2 > qdt) {
                        if (object2.bid > maxBid) {
                            maxBid = object2.bid;
                            maxBidQdt = qdt2;
                        }
                    }
                })
                object.maxBid = maxBid;
                object.maxBidQdt = maxBidQdt;
                object.maxBidPctInc = Number((((maxBid - object.ask) / object.ask) * 100).toFixed(2));
                return object; // also goes back to putsMap
            })
        })
        console.log('2putsMapByStrike: ', putsMapByStrike);

        console.log('Canvas getStock callsMap: ', callsMap);
        console.log('Canvas getStock putsMap: ', putsMap);
        console.log('Canvas getStock callsMapByStrike: ', callsMapByStrike);
        console.log('Canvas getStock putsMapByStrike: ', putsMapByStrike);

        let maxBidPctIncCallsBy5MinsMap = new Map(); // quoteDateTime5MinInc, maxBidPctInc
        callsMap.forEach((quoteMap, qdt5Mins) => {
            let maxBidPctInc = -999999;
            let quoteForMaxBidPctInc = {}
            quoteMap.forEach((quote, strike) => {
                if (quote.maxBidPctInc > maxBidPctInc) {
                    maxBidPctInc = quote.maxBidPctInc;
                    quoteForMaxBidPctInc = quote;
                    quoteForMaxBidPctInc.strike = strike;
                }
            });
            maxBidPctIncCallsBy5MinsMap.set(qdt5Mins, quoteForMaxBidPctInc);
        })
        let maxBidPctIncPutsBy5MinsMap = new Map(); // quoteDateTime5MinInc, maxBidPctInc
        putsMap.forEach((quoteMap, qdt5Mins) => {
            let maxBidPctInc = -999999;
            let quoteForMaxBidPctInc = {}
            quoteMap.forEach((quote, strike) => {
                if (quote.maxBidPctInc > maxBidPctInc) {
                    maxBidPctInc = quote.maxBidPctInc;
                    quoteForMaxBidPctInc = quote;
                    quoteForMaxBidPctInc.strike = strike;
                }
            });
            maxBidPctIncPutsBy5MinsMap.set(qdt5Mins, quoteForMaxBidPctInc);
        })
        console.log('maxBidPctIncCallsBy5MinsMap: ', maxBidPctIncCallsBy5MinsMap);
        console.log('maxBidPctIncPutsBy5MinsMap: ', maxBidPctIncPutsBy5MinsMap);
        // console.log('minCallStrikePrice: ', minCallStrikePrice); // also has maxBidPctInc
        // console.log('maxCallStrikePrice: ', maxCallStrikePrice); // also has maxBidPctInc
        // console.log('minPutStrikePrice: ', minPutStrikePrice); // also has maxBidPctInc
        // console.log('maxPutStrikePrice: ', maxPutStrikePrice); // also has maxBidPctInc


        // STROKE IF maxBidPctInc is high enough
        console.log('Canvas getStock ABOUT TO STROKE maxBidPctInc');
        let canvasHeight = this.state.canvasHeight;
        let canvasWidth = this.state.canvasWidth;
        let quoteDateTimeMap = this.state.quoteDateTimeMap;
        let quoteDateTimeRatio = this.state.quoteDateTimeRatio;
        const context3 = this.canvas3.current.getContext('2d');
        const context5 = this.canvas5.current.getContext('2d');
        context3.clearRect(0, 0, canvasWidth, canvasHeight);
        context5.clearRect(0, 0, canvasWidth, canvasHeight);
        //context3.strokeStyle = 'rgba(80,233,96,0.5)';
        context3.strokeStyle = 'green';
        context5.strokeStyle = 'rgba(80,76,146,0.5)';
        maxBidPctIncCallsBy5MinsMap.forEach((quote, qdt5Mins) => {
            if (quote.maxBidPctInc > 200) {
                if (quote.maxBidPctInc > 300) {
                    let x = Math.round(quoteDateTimeMap.get(qdt5Mins) * quoteDateTimeRatio);
                    //context5.strokeStyle = 'rgba(255,0,0,0.5)';          // now: strokeStyle instead of fillStyle
                    context5.beginPath();
                    context5.moveTo(x, 0);
                    context5.lineTo(x, canvasHeight);
                    context5.stroke();  // now: stroke() insted of fill()
                    context5.closePath();
                } else {
                    let x = Math.round(quoteDateTimeMap.get(qdt5Mins) * quoteDateTimeRatio);
                    //context3.strokeStyle = 'rgba(255,0,0,0.5)';          // now: strokeStyle instead of fillStyle
                    context3.beginPath();
                    context3.moveTo(x, 0);
                    context3.lineTo(x, canvasHeight);
                    context3.stroke();  // now: stroke() insted of fill()
                    context3.closePath();
                }
            }
        })
        maxBidPctIncPutsBy5MinsMap.forEach((quote, qdt5Mins) => {
            if (quote.maxBidPctInc > 200) {
                if (quote.maxBidPctInc > 300) {
                    let x = Math.round(quoteDateTimeMap.get(qdt5Mins) * quoteDateTimeRatio);
                    //context5.strokeStyle = 'rgba(255,0,0,0.5)';          // now: strokeStyle instead of fillStyle
                    context5.beginPath();
                    context5.moveTo(x, 0);
                    context5.lineTo(x, canvasHeight);
                    context5.stroke();  // now: stroke() insted of fill()
                    context5.closePath();
                } else {
                    let x = Math.round(quoteDateTimeMap.get(qdt5Mins) * quoteDateTimeRatio);
                    //context3.strokeStyle = 'rgba(255,0,0,0.5)';          // now: strokeStyle instead of fillStyle
                    context3.beginPath();
                    context3.moveTo(x, 0);
                    context3.lineTo(x, canvasHeight);
                    context3.stroke();  // now: stroke() insted of fill()
                    context3.closePath();
                }
            }
        })

        // STROKE OUT AFTER EXPIRATION DATE
        //let expDate = this.state.expDate; // we can probably remove this code
        // let expDateDateObject = new Date(2000 + Number(expDate.substr(0, 2)), Number(expDate.substr(2, 2)) - 1, Number(expDate.substr(4, 2)), 20);
        // console.log('expDateDateObject: ', expDateDateObject);
        // let expDateUnix = expDateDateObject.getTime() / 1000;
        // console.log('expDateUnix: ', expDateUnix);
        //let expDateUnix5Mins = Math.round(expDateUnix / 300);
        //console.log('expDateUnix5Mins: ', expDateUnix5Mins);
        const context4 = this.canvas4.current.getContext('2d');
        context4.strokeStyle = 'rgba(172,161,123,0.5)';
        context4.clearRect(0, 0, this.state.canvasWidth, this.state.canvasHeight);
        let loopReachedExpDate = false;
        quoteDateTimeMap.forEach((index, qdt5Mins) => {
            //console.log('qdt5Mins: ', qdt5Mins);
            //console.log('expDateUnix5Mins: ', expDateUnix5Mins);
            if ((qdt5Mins >= expDateUnixTimeDiv300) && (loopReachedExpDate === false)) {
                loopReachedExpDate = true;
                let x = Math.round(quoteDateTimeMap.get(qdt5Mins) * quoteDateTimeRatio);
                console.log('stroking at x: ', x);
                // context4.beginPath();
                // context4.moveTo(x, 0);
                // context4.lineTo(x, canvasHeight);
                // context4.stroke();  // now: stroke() insted of fill()
                // context4.closePath();
                context4.beginPath();
                context4.rect(x, 0, canvasWidth, canvasHeight);
                //context4.rect(0, 0, 200, 200);
                context4.fillStyle = "rgba(172, 161, 123, 0.5)";
                context4.fill();                                // now: stroke() insted of fill()
                context4.closePath();
            }
        })

        //console.log('countsWhereQDTIn5MinsIncNotInStockQuotesMap: ', countsWhereQDTIn5MinsIncNotInStockQuotesMap);
        //console.log('quotesWhereQDTIn5MinsIncNotInStockQuotesMapArray: ', quotesWhereQDTIn5MinsIncNotInStockQuotesMapArray);
        console.log('this.props.stockQuotesMap: ', this.props.stockQuotesMap);
        this.setState({ callsMap: callsMap });
        this.setState({ putsMap: putsMap });
        this.setState({ callsMapByStrike: callsMapByStrike });
        this.setState({ putsMapByStrike: putsMapByStrike });
        this.setState({ maxBidPctIncCallsBy5MinsMap: maxBidPctIncCallsBy5MinsMap });
        this.setState({ maxBidPctIncPutsBy5MinsMap: maxBidPctIncPutsBy5MinsMap });
        this.setState({ displayCallsMap: displayCallsMap });
        this.setState({ displayPutsMap: displayPutsMap });
        // this.setState({ callStrikeSet: callStrikeSet });
        // this.setState({ putStrikeSet: putStrikeSet });
        this.setState({ callStrikeArray: callStrikeArray });
        this.setState({ putStrikeArray: putStrikeArray });
        this.setState({ minCallStrikePrice: minCallStrikePrice });
        this.setState({ maxCallStrikePrice: maxCallStrikePrice });
        this.setState({ minPutStrikePrice: minPutStrikePrice });
        this.setState({ maxPutStrikePrice: maxPutStrikePrice });
        this.setState({ expDateUnixTime: expDateUnixTime });
        console.log('Canvas getOptionQuotes Done downloading data');
    }

    expirationCheckboxChange = (dataToTransfer) => {
        console.log('Canvas expirationCheckboxChange dataToTransfer: ', dataToTransfer);
        // expirationDateQuotesArray
        //console.log('Canvas: checkboxChange this.props: ', this.props);
        let isSelected = false;
        let expirationDateArray = []
        if (this.state.expirationDateArray.length > 0) {
            console.log('Canvas expirationCheckboxChange expirationDateArray.length > 0');
            console.log('Canvas expirationCheckboxChange this.state.expirationDateArray: ', this.state.expirationDateArray);
            expirationDateArray = this.state.expirationDateArray;
        } else {
            console.log('Canvas expirationCheckboxChange expirationDateArray.length <= 0');
            console.log('Canvas expirationCheckboxChange this.state.expirationDateArray: ', this.state.expirationDateArray);
            expirationDateArray = this.props.expirationDateArray;
        }
        //let expirationDateArray = this.props.expirationDateArray;
        expirationDateArray.map((thisObject) => {
            //console.log('expirationCheckboxChange: expDate: ', expDate);
            console.log('expirationCheckboxChange: thisObject: ', thisObject);
            if (thisObject.id === dataToTransfer.id) { // the id of the expDate itself
                console.log('Canvas expirationCheckboxChange changing');
                if (thisObject.selected) {
                    // thisObject.optionSymbolArray.map((symbol) => { // was using this before
                    //     symbol.selected = false;
                    // })
                    thisObject.selected = false;
                } else {
                    thisObject.selected = true;
                    isSelected = true;
                }
            }
            return thisObject;
        })
        this.setState({ expirationDateArray: expirationDateArray });
        // this.setState({
        //     expirationDateQuotesArray: this.state.expirationDateQuotesArray.map(thisObject => {
        //         //console.log('expirationCheckboxChange: expDate: ', expDate);
        //         if (thisObject.id === dataToTransfer.id) { // the id of the expDate itself
        //             //console.log('expDate.selected: ', expDate.selected);
        //             console.log('Canvas expirationCheckboxChange thisObject: ', thisObject);
        //             //thisObject.selected = !thisObject.selected
        //             if (thisObject.selected) {
        //                 thisObject.optionSymbolArray.map((symbol) => {
        //                     symbol.selected = false;
        //                 })
        //                 thisObject.selected = false;
        //             } else {
        //                 thisObject.selected = true;
        //                 isSelected = true;
        //             }
        //             //console.log('2expDate.selected: ', expDate.selected);
        //         }
        //         return thisObject;
        //     })

        // })
        console.log('isSelected: ', isSelected);
        if (isSelected) {
            this.getOptionQuotes(this.props.stockSymbol, dataToTransfer.expDate);
            this.setState({ expDate: dataToTransfer.expDate });
        } else {
            // WE MIGHT WANT TO SAVE THIS DATA IN OTHER MAPS SO WE DON'T HAVE TO RELOAD IT AGAIN
            this.setState({ callsMap: [] });
            this.setState({ putsMap: [] });
            this.setState({ displayCallsMap: [] });
            this.setState({ displayPutsMap: [] });
            this.setState({ callStrikeArray: [] });
            this.setState({ putStrikeArray: [] });
            this.setState({ expDate: 0 });
            this.setState({ maxCallStrikePrice: 0 });
            this.setState({ minCallStrikePrice: 999999 });
            this.setState({ maxPutStrikePrice: 0 });
            this.setState({ minPutStrikePrice: 999999 });
            const context3 = this.canvas3.current.getContext('2d');
            context3.clearRect(0, 0, this.state.canvasWidth, this.state.canvasHeight);
            const context4 = this.canvas4.current.getContext('2d');
            context4.clearRect(0, 0, this.state.canvasWidth, this.state.canvasHeight);
            const context5 = this.canvas5.current.getContext('2d');
            context5.clearRect(0, 0, this.state.canvasWidth, this.state.canvasHeight);
        }
    }

    optionCheckboxChange = (dataToTransfer) => {
        console.log('Canvas optionCheckboxChange dataToTransfer: ', dataToTransfer);
        console.log('Canvas optionCheckboxChange id: ', dataToTransfer.id);
        console.log('Canvas optionCheckboxChange expDate: ', dataToTransfer.expDate);
        // expirationDateQuotesArray
        //console.log('Canvas: checkboxChange this.props: ', this.props);
        let optionSymbolToChangeInChart = '';
        this.setState({
            expirationDateQuotesArray: this.state.expirationDateQuotesArray.map(thisObject => {
                //console.log('expirationCheckboxChange: expDate: ', expDate);
                if (thisObject.selected) { // the id of the expDate itself
                    console.log('Canvas optionCheckboxChange thisObject: ', thisObject);
                    thisObject.optionSymbolArray.map((symbolObject) => {
                        if (symbolObject.id === dataToTransfer.id) {
                            symbolObject.selected = !symbolObject.selected
                            optionSymbolToChangeInChart = symbolObject.optionSymbol;
                            console.log('Canvas optionCheckboxChange symbolObject: ', symbolObject);
                        }
                    })
                    //thisObject.selected = !thisObject.selected
                    //console.log('2expDate.selected: ', expDate.selected);
                }
                return thisObject;
            })

        })
        console.log('Canvas optionSymbolToChangeInChart: ', optionSymbolToChangeInChart);
        const isLargeNumber = (element) => element > 13;
        let arrayOfSymbolsToChart = [];
        if (this.state.optionSymbolsToChart.includes(optionSymbolToChangeInChart)) {
            // REMOVE IT
            console.log('Canvas Removing from this.state.optionSymbolsToChart : ', optionSymbolToChangeInChart);
            arrayOfSymbolsToChart = [...this.state.optionSymbolsToChart]; // make a separate copy of the array
            var index = arrayOfSymbolsToChart.indexOf(optionSymbolToChangeInChart)
            if (index !== -1) {
                arrayOfSymbolsToChart.splice(index, 1);
                console.log('arrayOfSymbolsToChart: ', arrayOfSymbolsToChart);
                this.setState({ optionSymbolsToChart: arrayOfSymbolsToChart });
            }
        } else {
            // ADD ANOTHER
            console.log('Canvas Adding to this.state.optionSymbolsToChart : ', optionSymbolToChangeInChart);
            arrayOfSymbolsToChart = [...this.state.optionSymbolsToChart];
            arrayOfSymbolsToChart.push(optionSymbolToChangeInChart);
            console.log('arrayOfSymbolsToChart: ', arrayOfSymbolsToChart);
            this.setState({
                optionSymbolsToChart: arrayOfSymbolsToChart
            })
        }
        //this.forceUpdate(); // DO WE NEED THIS?
        //console.log('Canvas - did setState hit yet? :', this.state.optionSymbolsToChart);
        this.updateCanvas(this.state.stockQuotes, arrayOfSymbolsToChart);
    }

    customAlertFunction(data) {
        console.log('Canvas customAlertFunction data: ', data);
        console.log('Canvas customAlertFunction this.state.displayAlertIsActive: ', this.state.displayAlertIsActive);
        if (data === null) {
            //this.setState({ displayAlertIsActive: !this.state.displayAlertIsActive });
        } else {
            this.setState({ customAlertData: data });
            this.setState({ displayAlertIsActive: true });
        }
    }

    changeAlert() {
        console.log('Canvas changeAlert')
        this.setState({ displayAlertIsActive: !this.state.displayAlertIsActive });
    }

    expDateSetIsShownChange = (e) => {
        console.log('Canvas expDateSetIsShownChange e.target: ', e.target);
        let trueOrFalse = this.state.expDateSetIsShown;
        console.log('this.state.expDateSetIsShown: ', this.state.expDateSetIsShown);
        console.log('this.state.expirationDateArray: ', this.state.expirationDateArray);
        // if (trueOrFalse === true) {
        //     e.target.style.background = 'red';
        // } else {
        //     e.target.style.background = 'blue';
        // }
        this.setState({ expDateSetIsShown: !this.state.expDateSetIsShown });
    }

    async changeStock(e, newStockSymbol) {
        e.preventDefault();
        console.log('Canvas changeStock: newStockSymbol: ', newStockSymbol);
        let trueOrFalse = this.state.changeStockIsShown;
        //console.log('this.state.changeStockIsShown: ', this.state.changeStockIsShown);
        // if (trueOrFalse === true) {
        //     e.target.style.background = 'red';
        // } else {
        //     e.target.style.background = 'blue';
        // }
        this.setState({ changeStockIsShown: !this.state.changeStockIsShown });
        if (newStockSymbol !== undefined) {
            await this.props.getStockSymbol(newStockSymbol);
            this.setState({ expirationDateArray: [] });
            this.updateCanvas(null, null);
        }
    }

    render() {
        //console.log('Canvas quotes: ', this.props.quotes);
        return (
            <div id="wrapper" >
                <div className="item1" id='item1'>
                    <canvas id='stockMenu' ref={this.stockMenu} width={40} height={600}></canvas>
                </div>
                <div className="item2" ref={this.item2} id="item2" >
                    <canvas id='canvas' ref={this.canvas} width={this.state.canvasWidth} height={this.state.canvasHeight}></canvas>
                    <canvas id='canvas2' ref={this.canvas2} onMouseMove={this.handleOnMouseMove} onClick={this.onClick} width={this.state.canvasWidth} height={this.state.canvasHeight}></canvas>
                    <canvas id='canvas3' ref={this.canvas3} width={this.state.canvasWidth} height={this.state.canvasHeight}></canvas>
                    <canvas id='canvas4' ref={this.canvas4} width={this.state.canvasWidth} height={this.state.canvasHeight}></canvas>
                    <canvas id='canvas5' ref={this.canvas5} width={this.state.canvasWidth} height={this.state.canvasHeight}></canvas>
                    <canvas id='canvas6' ref={this.canvas6} width={this.state.canvasWidth} height={this.state.canvasHeight}></canvas>
                </div>
                {/* <div class="item5" id="item5">
                    <OptionSymbolsPuts expirationDateQuotesArray={this.state.expirationDateQuotesArray} checkboxChange={this.expirationCheckboxChange.bind(this)} optionCheckboxChange={this.optionCheckboxChange.bind(this)} />
                </div> */}
                <div className="item3a" id="item3a" >
                    <ChangeStockHeader id="changestockheader" changeStockIsShown={this.state.changeStockIsShown} changeStock={this.changeStock.bind(this)} />
                    {this.state.changeStockIsShown ?
                        <ChangeStock changeStock={this.changeStock.bind(this)} />
                        :
                        null}
                </div>
                <div className="item3" id="item3" >
                    <ExpDateHeader id="expdateheader" expDateSetIsShown={this.state.expDateSetIsShown} expDateSetIsShownChange={this.expDateSetIsShownChange} />
                    {this.state.expDateSetIsShown ?
                        <ExpDate expirationDateArray={this.state.expirationDateArray} checkboxChange={this.expirationCheckboxChange.bind(this)} />
                        :
                        null}
                </div>
                <div className="item5" id="item5">
                    <PutsHeader formattedDate={this.state.formattedDate} />
                    <PutPrices customAlertFunction={this.customAlertFunction} displayPutsMap={this.state.displayPutsMap} putsMap={this.state.putsMap} putsMapByStrike={this.state.putsMapByStrike} current5MinQdt={this.state.current5MinQdt} putStrikeArray={this.state.putStrikeArray} minPutStrikePrice={this.state.minPutStrikePrice} maxPutStrikePrice={this.state.maxPutStrikePrice} checkboxChange={this.expirationCheckboxChange.bind(this)} optionCheckboxChange={this.optionCheckboxChange.bind(this)} expDateUnixTime={this.state.expDateUnixTime} />
                </div>
                <div className="item4" id="item4">
                    <CallsHeader formattedDate={this.state.formattedDate} />
                    <CallPrices customAlertFunction={this.customAlertFunction} displayCallsMap={this.state.displayCallsMap} callsMap={this.state.callsMap} callsMapByStrike={this.state.callsMapByStrike} current5MinQdt={this.state.current5MinQdt} callStrikeArray={this.state.callStrikeArray} minCallStrikePrice={this.state.minCallStrikePrice} maxCallStrikePrice={this.state.maxCallStrikePrice} checkboxChange={this.expirationCheckboxChange.bind(this)} optionCheckboxChange={this.optionCheckboxChange.bind(this)} expDateUnixTime={this.state.expDateUnixTime} />
                </div>
                {/* <div class="item2a" id='item2a'>
                    <canvas id='optionMenu' ref='optionMenu' width={50} height={600}></canvas>
                </div> */}
                {this.state.displayAlertIsActive ?
                    <CustomAlert changeAlert={this.changeAlert} customAlertFunction={this.customAlertFunction} customAlertData={this.state.customAlertData} /> :
                    null
                }

            </div>
        )
    }
}

export default Canvas;
