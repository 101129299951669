import React, { Component } from 'react'
import PutPricesItem from './PutPricesItem';

class PutPrices extends Component {
    render() {
        //console.log('PutPrices this.props.putStrikeArray: ', this.props.putStrikeArray);
        //console.log('PutPrices this.props: ', this.props);
        return this.props.putStrikeArray.map((strike) => {
            return (
                <PutPricesItem customAlertFunction={this.props.customAlertFunction} displayPutsMap={this.props.displayPutsMap} putsMapByStrike={this.props.putsMapByStrike} current5MinQdt={this.props.current5MinQdt} strike={strike} checkboxChange={this.props.checkboxChange} optionCheckboxChange={this.props.optionCheckboxChange} expDateUnixTime={this.props.expDateUnixTime} />
            )
        })
    }
}

export default PutPrices