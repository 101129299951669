import React, { Component } from 'react'
import ExpDateItem from './ExpDateItem';
import OptionSymbolCallItem from './OptionSymbolCallItem';
import CallPricesItem from './CallPricesItem';

class CallPrices extends Component {
    render() {
        //console.log('CallPrices this.props.callStrikeArray: ', this.props.callStrikeArray);
        //console.log('CallPrices this.props: ', this.props);
        return this.props.callStrikeArray.map((strike) => {
            return (
                <CallPricesItem customAlertFunction={this.props.customAlertFunction} displayCallsMap={this.props.displayCallsMap} callsMapByStrike={this.props.callsMapByStrike} current5MinQdt={this.props.current5MinQdt} strike={strike} checkboxChange={this.props.checkboxChange} optionCheckboxChange={this.props.optionCheckboxChange} expDateUnixTime={this.props.expDateUnixTime} />
            )
        })
    }
}

export default CallPrices