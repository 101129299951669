import React, { Component } from 'react'
import axios from 'axios'
import './Summary2.css';
import { v4 as uuid } from 'uuid'

class Summary2 extends Component {
    state = {
        summary: [],
        sortOrder: 'ascending',
        stockSymbol: ''
        // {
        //     dateTime = 'ascending',
        //     stockSymbol = 'ascending',
        //     maxBidPctInc = 'ascending',
        //     numberOfOptions = 'ascending',
        //     secondsUsed = 'ascending',
        //     optionSymbol = 'ascending',
        //     daysUntilExp = 'ascending',
        //     putOrCall = 'ascending',
        //     exch = 'ascending'
        // }
    }

    componentDidMount() {
        //axios.get('http://167.99.4.52:3000/stock')
        axios.get('https://securedoug.com/summaryreact')
            .then(res => this.setState({ summary: res.data }))
    }

    // flipSortOrder(sortedField) {
    //     this.setState({ sortOrder.sortedField.: 'ascending'? 'descending' : 'ascending' })
    // }

    setSortedField(sortedField) {
        console.log('starting setSortedField, sortedField: ', sortedField);
        //let sortName = 'sortOrder' + sortedField;
        //console.log('sortName: ', sortName);
        if (this.state.sortOrder == 'ascending') {
            console.log('changing to descending');
            this.setState({ sortOrder: 'descending' })
        } else if (this.state.sortOrder == 'descending') {
            console.log('changing to ascending');
            this.setState({ sortOrder: 'ascending' })
        }
        console.log(this.state);
        //this.state.sortName = 'ascending' ? 'descending' : 'ascending'
        console.log('this.state.sortOrder: ', this.state.sortOrder);
        if (sortedField !== null) {
            this.state.summary.sort((a, b) => {
                if (a[sortedField] < b[sortedField]) {
                    //console.log('-1');
                    return this.state.sortOrder === 'ascending' ? -1 : 1;
                }
                if (a[sortedField] > b[sortedField]) {
                    //console.log('1');
                    return this.state.sortOrder === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        this.forceUpdate();
    }

    // getStockSymbol(symbol) {
    //     console.log('Summary2 getStockSymbol symbol: ', symbol);
    //     this.setState({stockSymbol: symbol});
    // }

    setStockSymbol(symbol) {
        console.log('Summary2 setStockSymbol symbol: ', symbol);
        this.setState({ stockSymbol: symbol });
    }

    getStyle = () => {
        return {
            background: '#f4f4f4',
            padding: '10px',
            borderBottom: '1px #ccc dotted',
            //margin: 'auto'
            //textDecoration: 'line-through'
        }
    }

    render() {
        console.log('summary in render: ', this.state.summary);
        //const [sortedField, setSortedField] = React.useState(null);
        this.state.summary.map((line) => {
            if (line.dateTime > 1000000000) {
                //console.log('line.dateTime: ', line.dateTime)
                line.dateTime = new Date(line.dateTime * 1000).toLocaleString();
            }
            line.id = uuid();
        });
        return (
            <div class='scrollable'>
                <table class='timecard' id='myTable'>
                    <caption>Summary</caption>
                    <thead>
                        <tr>
                            <th id='thDateTime'>
                                <button class='button' type="button" onClick={() => this.setSortedField('dateTime')}>
                                    dateTime
                            </button>
                            </th>
                            <th id='thStockSymbol'>
                                <button class='button' type="button" onClick={() => this.setStockSymbol('stockSymbol')}>
                                    stockSymbol
                            </button>
                            </th>
                            <th id='thMaxBidPctInc'>
                                <button class='button' type="button" onClick={() => this.setSortedField('maxBidPctInc')}>
                                    maxBidPctInc
                            </button>
                            </th>
                            <th id='pctQDT100Plus'>
                                <button class='button' type="button" onClick={() => this.setSortedField('pctQDT100Plus')}>
                                    pctQDT100Plus
                                </button>
                            </th>
                            <th id='pctQDT200Plus'>
                                <button class='button' type="button" onClick={() => this.setSortedField('pctQDT200Plus')}>
                                    pctQDT200Plus
                                </button>
                            </th>
                            <th id='pctQDT300Plus'>
                                <button class='button' type="button" onClick={() => this.setSortedField('pctQDT300Plus')}>
                                    pctQDT300Plus
                                </button>
                            </th>
                            <th>
                                <button class='button' type="button" onClick={() => this.setSortedField('numberOfOptions')}>
                                    numberOfOptions
                            </button>
                            </th>
                            <th id='thSecondsUsed'>
                                <button class='button' type="button" onClick={() => this.setSortedField('secondsUsed')}>
                                    secondsUsed
                            </button>
                            </th>
                            <th id='thOptionSymbol'>optionSymbol</th>
                            <th id='thDaysUntilExp'>
                                <button class='button' type="button" onClick={() => this.setSortedField('daysUntilExp')}>
                                    daysUntilExp
                            </button>
                            </th>
                            <th id='thPutOrCall'>
                                <button class='button' type="button" onClick={() => this.setSortedField('putOrCall')}>
                                    putOrCall
                                </button>
                            </th>
                            <th id='thExch'>
                                <button class='button' type="button" onClick={() => this.setSortedField('exch')}>
                                    exch
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody class='tableTbody'>
                        {this.state.summary.map((line, index) => (
                            <tr key={line.id}>
                                <td>{line.dateTime}</td>
                                <td>
                                    <button class='button' type="button" onClick={() => this.props.getStockSymbol(line.stockSymbol)}>
                                        {line.stockSymbol}
                                    </button>
                                </td>
                                <td>{line.maxBidPctInc}</td>
                                <td>{line.pctQDT100Plus}</td>
                                <td>{line.pctQDT200Plus}</td>
                                <td>{line.pctQDT300Plus}</td>
                                <td>{line.numberOfOptions}</td>
                                <td>{line.secondsUsed}</td>
                                <td>{line.optionSymbol}</td>
                                <td>{line.daysUntilExp}</td>
                                <td>{line.putOrCall}</td>
                                <td>{line.exch}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Summary2