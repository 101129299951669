import React, { Component } from 'react'
import CallOption from './CallOption';

class OptionSymbolCallItem extends Component {

    // state = {
    //     id: 0,
    //     selected: false,
    //     optionQuote: '0'
    // }

    getStyle = () => {
        return {
            backgroundColor: '#feb236',
            borderBottom: '1px #ccc dotted',
            padding: '10px',
            height: this.props.experationDateSetHeight,
            width: this.props.experationDateSetwidth
        }
    }

    render() {
        console.log('OptionSymbolItem: this.props: ', this.props);
        const { id, selected, expDate, optionSymbolArray } = this.props.expDateObject;
        console.log('OptionSymbolItem: id: ', id);
        console.log('OptionSymbolItem: selected: ', selected);
        console.log('OptionSymbolItem: expDate: ', expDate);
        console.log('OptionSymbolItem: optionSymbolArray: ', optionSymbolArray);
        let dataToTransfer = {
            id: id,
            expDate: expDate
        }
        return this.props.expDateObject.selected === true ?
            this.props.expDateObject.optionSymbolArray.map((option) => (
                <CallOption option={option} checkboxChange={this.props.checkboxChange} optionCheckboxChange={this.props.optionCheckboxChange} />
            ))
            : null
    }
}

export default OptionSymbolCallItem;
