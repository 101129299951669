import React, { Component } from 'react'

class CallsHeader extends Component {

    getTopHeaderStyle = () => {
        return {
            width: '340px',
            backgroundColor: '#feb236',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '20px'
        }
    }

    getDateTimeStyle = () => {
        return {
            width: '340px',
            backgroundColor: '#feb236',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '17px'
        }
    }

    getBottomHeaderStyle = () => {
        return {
            width: '340px',
            backgroundColor: '#feb236',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '14px'
        }
    }

    getStyle = () => {
        return {
            float: 'left',
            marginLeft: '7px',
            marginRight: '7px'
        }
    }

    getStrikeStyle = () => {
        return {
            float: 'left',
            marginLeft: '12px',
            marginRight: '12px'
        }
    }

    getBidStyle = () => {
        return {
            float: 'left',
            marginLeft: '7px',
            marginRight: '7px'
        }
    }

    getAskStyle = () => {
        return {
            float: 'left',
            marginLeft: '7px',
            marginRight: '7px'
        }
    }

    getOpenIntStyle = () => {
        return {
            float: 'left',
            marginLeft: '7px',
            marginRight: '7px'
        }
    }

    getImplVolStyle = () => {
        return {
            float: 'left',
            marginLeft: '7px',
            marginRight: '7px'
        }
    }

    getMaxBidPctIncStyle = () => {
        return {
            float: 'left',
            marginLeft: '7px',
            marginRight: '7px'
        }
    }

    // getStyle2 = () => {
    //     return {
    //         float: 'right',
    //         marginLeft: '11px',
    //         marginRight: '11px'
    //     }
    // }

    render() {
        //console.log('CallsHeader this.props: ', this.props);
        //console.log('this.props.formattedDate: ', this.props.formattedDate);
        let formattedDate = this.props.formattedDate;
        if (formattedDate === 'NaN-NaN-NaN NaN:NaN') {
            formattedDate = '';
        }
        return (
            <div>
                <div style={this.getTopHeaderStyle()}>Calls</div>
                <div style={this.getDateTimeStyle()}>{formattedDate}</div>
                <div style={this.getBottomHeaderStyle()}>
                    <div style={this.getStrikeStyle()}>Strike</div>
                    <div style={this.getBidStyle()}>Bid</div>
                    <div style={this.getAskStyle()}>Ask</div>
                    <div style={this.getOpenIntStyle()}>openInt</div>
                    <div style={this.getImplVolStyle()}>implVol</div>
                    <div style={this.getMaxBidPctIncStyle()}>maxBidPctInc</div>
                </div>
            </div>
        )
    }
}

export default CallsHeader;
