import React, { Component } from 'react'

class PutsHeader extends Component {

    getTopHeaderStyle = () => {
        return {
            width: '340px',
            backgroundColor: '#feb236',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '20px'
        }
    }

    getDateTimeStyle = () => {
        return {
            width: '340px',
            backgroundColor: '#feb236',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '17px'
        }
    }

    getBottomHeaderStyle = () => {
        return {
            width: '340px',
            backgroundColor: '#feb236',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '14px'
        }
    }

    getStyle = () => {
        return {
            float: 'left',
            marginLeft: '7px',
            marginRight: '7px'
        }
    }

    render() {
        let formattedDate = this.props.formattedDate;
        if (formattedDate === 'NaN-NaN-NaN NaN:NaN') {
            formattedDate = '';
        }
        return (
            <div>
                <div style={this.getTopHeaderStyle()}>Puts</div>
                <div style={this.getDateTimeStyle()}>{formattedDate}</div>
                <div style={this.getBottomHeaderStyle()}>
                    <div style={this.getStyle()}>Strike</div>
                    <div style={this.getStyle()}>Bid</div>
                    <div style={this.getStyle()}>Ask</div>
                    <div style={this.getStyle()}>openInt</div>
                    <div style={this.getStyle()}>implVol</div>
                    <div style={this.getStyle()}>maxBidPctInc</div>
                </div>
            </div>
        )
    }
}

export default PutsHeader;
