import React, { Component } from 'react'

class ChangeStock extends Component {

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            newStockSymbol: '',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({ newStockSymbol: e.target.value });
    }

    render() {
        let newStockSymbol = this.state.newStockSymbol;
        return (
            <form id="changeStockForm" onSubmit={(e) => this.props.changeStock(e, newStockSymbol)}>
                <label id="changeStockLabel">
                    Symbol:
                <input id="changeStockTextInput" type="text" name="symbol" onChange={this.handleChange} value={this.state.newStockSymbol} />
                </label>
                <input id="changeStockSubmitButton" type="submit" value="OK" />
            </form>
        )
    }
}

export default ChangeStock;
