import React, { Component } from 'react'
import CallItem from './CallItem';

class PutPricesItem extends Component {

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {}
        //this.onMouseOver = this.onMouseOver.bind(this);
        //this.onClick = this.onClick.bind(this);
    }

    getStyle = () => {
        return {
            backgroundColor: '#feb236',
            borderBottom: '1px #ccc dotted',
            // padding: '10px',
            height: '20px',
            width: '300px'
        }
    }

    getLeftDivStyle = () => {
        return {
            backgroundColor: '#feb236',
            borderBottom: '1px #ccc dotted',
            // padding: '10px',
            height: '20px',
            width: '50px',
            fontSize: '15px',
            //position: 'relative',
            // border: '1px solid red',
            float: 'left',
            textAlign: 'center',
            cursor: 'pointer'
        }
    }

    getRightDivStyle = () => {
        return {
            backgroundColor: '#feb236',
            borderBottom: '1px #ccc dotted',
            // padding: '10px',
            height: '20px',
            width: '50px',
            fontSize: '15px',
            //position: 'relative',
            float: 'left',
            // border: '1px solid red',
            textAlign: 'center'
        }
    }

    onClick(completeObject) {
        console.log('PutPricesItem ONCLICK completeObject: ', completeObject);
        // let completeObject = {}
        // if (this.props.callsMapByStrike !== undefined) {
        //     console.log('CallPricesItem this.props.callsMapByStrike.get(this.props.strike): ', this.props.callsMapByStrike.get(this.props.strike));
        //     completeObject = this.props.callsMapByStrike.get(this.props.strike).get(valueObject.qdt5Mins);
        // }
        // console.log('CallPricesItem completeObject: ', completeObject);
        ////let maxBidPctInc = this.props.displayCallsMap.get(this.props.strike).maxBidPctInc
        let objectForThisStrike = this.props.displayPutsMap.get(this.props.strike);
        console.log('PutPricesItem ONCLICK objectForThisStrike: ', objectForThisStrike + ' AskPrice: ' + objectForThisStrike.ask);
        let textArray = []
        let maxBidPctInc = objectForThisStrike.maxBidPctInc;
        textArray.push('Put:  StrikePrice: ' + this.props.strike + ' AskPrice: $ ' + objectForThisStrike.ask);
        textArray.push('maxBidPctInc: ' + maxBidPctInc + '%');
        let maxBidQdt = objectForThisStrike.maxBidQdt;
        let maxBidDateObject = new Date(maxBidQdt * 300 * 1000);
        let currentDateObject = new Date(this.props.current5MinQdt * 300 * 1000);
        let maxBid = objectForThisStrike.maxBid;
        textArray.push('Maximum Bid: $ ' + maxBid);
        let qdt5Mins = objectForThisStrike.qdt5Mins;
        let qdtDiff = (maxBidQdt - qdt5Mins) * 300;
        let days = Math.round(qdtDiff / 86400);
        textArray.push('Buy at ' + currentDateObject);
        textArray.push('Sell at ' + maxBidDateObject);
        textArray.push('daysTilSell: ' + days);
        let expDateUnixTime = this.props.expDateUnixTime;
        let qdtExpDateDiff = (expDateUnixTime - (qdt5Mins * 300));
        let daysTilExpDate = Math.round(qdtExpDateDiff / 86400);
        textArray.push('daysTilExpDate: ' + daysTilExpDate);
        ////let maxBidPctIncText = 'CallPricesItem ONCLICK maxBidPctInc: ' + maxBidPctInc + '%';
        //alert(maxBidPctIncText);
        if (maxBidPctInc !== undefined) {
            this.props.customAlertFunction(textArray);
        } else {
            this.props.customAlertFunction('undefined');
        }
        console.log('PutPricesItem ONCLICK this.props.displayPutsMap.get(this.props.strike).maxBidPctInc: ', this.props.displayPutsMap.get(this.props.strike).maxBidPctInc);
    }

    render() {
        //console.log('PutPricesItem: this.props: ', this.props);
        //let thisPrice = 10.50;
        //console.log('PutPricesItem Render: this.props.strike: ', this.props.strike);
        let valueObject = this.props.displayPutsMap.get(this.props.strike);
        const { bid, ask, openInt, impliedVol, maxBidPctInc } = valueObject;
        // if (valueObject.bid !== undefined) {
        //     console.log('PutPricesItem valueObject: ', valueObject);
        // }
        // let bid = valueObject.bid;
        // let ask = valueObject.ask;
        // let openInt = valueObject.openInt;
        // let impliedVol = valueObject.impliedVol;
        // console.log('PutPricesItem render valueObject.maxBidPctInc: ', valueObject.maxBidPctInc);
        // if (maxBidPctInc != undefined) {
        //     var maxBidPctInc = valueObject.maxBidPctInc + ' %';
        // }
        return (
            <div style={this.getStyle()} >
                <div style={this.getLeftDivStyle()} onClick={this.onClick.bind(this)}>
                    <p>
                        {this.props.strike}
                    </p>
                </div>
                <div style={this.getRightDivStyle()} >
                    <p>
                        {bid}
                    </p>
                </div>
                <div style={this.getRightDivStyle()} >
                    <p>
                        {ask}
                    </p>
                </div>
                <div style={this.getRightDivStyle()} >
                    <p>
                        {openInt}
                    </p>
                </div>
                <div style={this.getRightDivStyle()} >
                    <p>
                        {impliedVol}
                    </p>
                </div>
                <div style={this.getRightDivStyle()} >
                    <p>
                        {maxBidPctInc}
                    </p>
                </div>
            </div>
        )


    }
}

export default PutPricesItem;
