import React, { Component } from 'react'

class ExpDateItem extends Component {

    // state = {
    //     id: 0,
    //     selected: false,
    //     optionQuote: '0'
    // }

    getStyle = () => {
        return {
            backgroundColor: '#feb236',
            borderBottom: '1px #ccc dotted',
            padding: '5px',
            // height: this.props.experationDateSetHeight,
            // width: this.props.experationDateSetwidth
            height: '25px',
            width: '90px'
        }
    }

    render() {
        //console.log('ExpDateItem: this.props: ', this.props);
        const { id, selected, expDate, optionSymbolArray } = this.props.expDateObject;
        // console.log('ExpDateItem: id: ', id);
        // console.log('ExpDateItem: selected: ', selected);
        // console.log('ExpDateItem: expDate: ', expDate);
        // console.log('ExpDateItem: optionSymbolArray: ', optionSymbolArray);
        let dataToTransfer = {
            id: id,
            expDate: expDate
        }
        return (
            <div style={this.getStyle()} >
                <p>
                    <input type="checkbox" checked={this.props.expDateObject.selected} onChange={this.props.checkboxChange.bind(this, dataToTransfer)} />
                    {expDate}
                    {/* {selected === true ?
                        this.props.expDateObject.optionSymbolArray.forEach((optionSymbol, selected, id) => (
                            { optionSymbol }
                        ))
                        : null} */}
                </p>
            </div>
        )
    }
}

export default ExpDateItem;
