import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

//const element = <FontAwesomeIcon icon={faCaretDown} />

class ExpDateHeader extends Component {

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {}
    }

    getHeaderStyle = () => {
        return {
            width: '100px',
            height: '20px',
            backgroundColor: '#feb236',
            border: '1px solid green',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '16px'
        }
    }

    getStyle = () => {
        return {
            float: 'left',
            marginLeft: '12px',
            marginRight: '12px'
        }
    }



    render() {
        let expDateSetIsShown = this.props.expDateSetIsShown;
        let textString = ' Exp Dates';
        return (
            <div className='glow-on-hover' expDateSetIsShown={this.props.expDateSetIsShown} onClick={(e) => this.props.expDateSetIsShownChange(e)} style={this.getHeaderStyle()} >
                {expDateSetIsShown ?
                    <FontAwesomeIcon icon={faCaretUp} />
                    :
                    <FontAwesomeIcon icon={faCaretDown} />
                }
                {textString}
            </div>
        )
    }
}

export default ExpDateHeader
