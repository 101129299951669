import React, { Component } from 'react'
import mainLogo from './images/ic_stat_grade.png';

class CustomAlert extends Component {

    // getStyle = () => {
    //     return {
    //         backgroundColor: '#feb236',
    //         borderBottom: '1px #ccc dotted',
    //         // padding: '10px',
    //         height: '20px',
    //         width: '300px'
    //     }
    // }

    getStyle = () => {
        return {
            type1: {
                backgroundColor: '#feb236',
                borderBottom: '1px #ccc dotted',
                // padding: '10px',
                height: '20px',
                width: '300px'
            },
            type2: {
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f5fcff'
            },
            mainContainer: {
                flexDirection: 'column',
                height: '70%',
                width: '60%',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#404040',
                borderWidth: 2,
                borderColor: '#ff0000',
                borderRadius: 10,
                padding: 4,
                zIndex: 2,
                position: 'absolute',
                top: '150px',
                left: '300px'
            },
            topRow: {
                flex: 0.5,
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                borderWidth: 1,
                borderColor: '#00ff00',
                paddingHorizontal: 2,
                paddingVertical: 4
            },
            middleRow: {
                flex: 1,
                width: '100%',
                borderWidth: 1,
                borderColor: '#ff6600',
                textAlign: 'left',
                textAlignVertical: 'center',
                padding: 20,
                color: '#ffffff',
                fontSize: 17,
                marginVertical: 2
            },
            bottomRow: {
                flex: 0.5,
                width: '100%',
                borderWidth: 1,
                borderColor: '#0066ff',
                flexDirection: 'row',
                padding: 4,
                justifyContent: 'space-evenly'
            },
            alertIconStyle: {
                borderWidth: 1,
                borderColor: '#cc00cc',
                height: 40,
                width: 40
            },
            alertTitleTextStyle: {
                flex: 1,
                textAlign: 'justify',
                color: '#ffffff',
                fontSize: 18,
                fontWeight: 'bold',
                borderWidth: 1,
                borderColor: '#660066',
                padding: 2,
                marginHorizontal: 2
            },
            alertMessageButtonStyleLeft: {
                paddinHorizontal: 6,
                marginVertical: 4,
                borderRadius: 10,
                backgroundColor: '#80BFFF',
                justifyContent: 'center',
                height: '10%',
                width: '10%',
                float: 'left',
                cursor: 'grab'
            },
            alertMessageButtonStyleRight: {
                paddingHorizontal: 6,
                marginVertical: 4,
                borderRadius: 10,
                backgroundColor: '#80BFFF',
                justifyContent: 'center',
                height: '10%',
                width: '10%',
                float: 'right',
                cursor: 'grab'
            },
            alertMessageButtonText: {
                fontSize: 14,
                fontWeight: 'bold',
                color: '#ffffff'
            }
        }
    }

    // customAlertFunction(data) {
    //     console.log('CustomAlert customAlertFunction data: ', data);
    // }


    render() {
        console.log('CustomAlert render this.props.customAlertData: ', this.props.customAlertData);
        return (
            <div style={this.getStyle().mainContainer} >
                <div style={this.getStyle().topRow} >
                    <img src={mainLogo} alt="this is alert png" style={this.getStyle().alertIconStyle} />
                </div>
                <div style={this.getStyle().middleRow} >
                    <p>{this.props.customAlertData[0]}</p>
                    <p>{this.props.customAlertData[1]}</p>
                    <p>{this.props.customAlertData[2].toString()}</p>
                    <p>{this.props.customAlertData[3]}</p>
                    <p>{this.props.customAlertData[4]}</p>
                    <p>{this.props.customAlertData[5]}</p>
                    <p>{this.props.customAlertData[6]}</p>
                </div>
                <div style={this.getStyle().bottomRow} >
                    <div style={this.getStyle().alertMessageButtonStyleLeft} >
                        <p onClick={this.props.changeAlert} style={this.getStyle().alertMessageButtonText} >OK/YES</p>
                    </div>
                    <div style={this.getStyle().alertMessageButtonStyleRight}>
                        <p onClick={this.props.changeAlert} style={this.getStyle().alertMessageButtonText} >CANCEL/NO</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default CustomAlert
