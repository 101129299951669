import React, { Component } from 'react'

class CallItem extends Component {

    // state = {
    //     id: 0,
    //     selected: false,
    //     optionQuote: '0'
    // }

    getStyle = () => {
        return {
            backgroundColor: '#feb236',
            borderBottom: '1px #ccc dotted',
            padding: '10px',
            height: this.props.experationDateSetHeight,
            width: this.props.experationDateSetwidth
        }
    }

    render() {
        //console.log('Option: this.props: ', this.props);
        let symbol = this.props.option.optionSymbol;
        let id = this.props.option.id;
        let expDate = this.props.option.expDate;
        let putOrCall = this.props.option.putOrCall;
        let dataToTransfer = {
            id: id,
            expDate: expDate,
            putOrCall: putOrCall
        }
        return this.props.option.putOrCall === 'Call' ?
            // (
            <div style={this.getStyle()} >
                <p>
                    <input type="checkbox" onChange={this.props.optionCheckboxChange.bind(this, dataToTransfer)} />
                    {symbol}
                </p>
            </div>
            :
            null
        // )
    }
}

export default CallItem;
