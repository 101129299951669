import React, { Component } from 'react'
import ExpDateItem from './ExpDateItem';

class ExpDate extends Component {


    render() {
        //console.log('ExpDate this.props.expirationDateArray: ', this.props.expirationDateArray);
        //console.log('ExpDate this.props: ', this.props);
        return (
            this.props.expirationDateArray.map((expDateObject, index) => (
                <ExpDateItem id={index} expDateObject={expDateObject} checkboxChange={this.props.checkboxChange} width='100' height='500' />
            )))
    }
}

export default ExpDate;