import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Header from './components/layout/Header';
//import Quotes from './components/Quotes';
import Canvas from './components/Canvas';
import './App.css';
import About from './components/pages/About';
import Summary2 from './components/pages/Summary2';
//import { render } from '@testing-library/react';
import axios from 'axios';
//import uuid from 'uuid';

class App extends Component {
  state = {
    quotes: [],
    stockSymbol: 'KO',
    stockQuotesMap: [],
    quoteDateTimeSet: [],
    expirationDateSet: [],
    expirationDateQuotesMap: [],
    expirationDateQuotesArray: [],
    expirationDateArray: [],
    stockInfo: []
  }

  async getStockSymbol(symbol) {
    console.log('App getStockSymbol symbol: ', symbol);
    // axios.get('https://securedoug.com/stockreact/' + symbol)
    //   .then(res => this.setState({ quotes: res.data }))
    const res = await axios.get('https://securedoug.com/stockreact/stockquotes/' + symbol);
    const { data } = res;
    //console.log('App data: ', data);
    // const optionSymbolsSet = [...new Set(data.map(item => item.optionSymbol))];
    const quoteDateTimeSet = [...new Set(data.map(item => item.quoteDateTime))];
    console.log('App quoteDateTimeSet: ', quoteDateTimeSet);
    let stockQuotesMap = new Map();
    quoteDateTimeSet.map((quoteDateTime) => {
      let stockPrice = data.find((quote) => quote.quoteDateTime === quoteDateTime).stockPrice;
      stockQuotesMap.set(Math.round(quoteDateTime / 300), stockPrice);
      //console.log('App quoteDateTime: ', new Date(quoteDateTime * 1000));
      //console.log('App quoteDateTime, stockPrice: ', quoteDateTime, stockPrice);
    })
    ////console.log('App stockQuotesMap: ', stockQuotesMap);

    const res2 = await axios.get('https://securedoug.com/stockreact/expdatesforstock/' + symbol);

    //console.log('res2: ', res2);

    //const { data } = res2;

    const expirationDateSet = [...new Set(res2.data.map(item => item.expDate))];
    ////console.log('App expirationDateSet: ', expirationDateSet);

    // let expirationDateSet = new Set();
    // let expirationDateQuotesMap = new Map();
    // let id = 1000;
    // optionSymbolsSet.forEach((optionSymbol) => {
    //   let expiration = optionSymbol.substr(optionSymbol.indexOf("2"), 6);
    //   expirationDateSet.add(expiration);
    //   let putOrCallLetter = optionSymbol.substr(optionSymbol.indexOf("2") + 6, 1);
    //   console.log('putOrCallLetter: ', putOrCallLetter);
    //   let putOrCall = '';
    //   if (putOrCallLetter == 'P') {
    //     putOrCall = 'Put';
    //   } else if (putOrCallLetter == 'C') {
    //     putOrCall = 'Call';
    //   } else {
    //     console.log('putOrCall Error');
    //     throw 1;
    //   }
    //   //console.log('putOrCall: ', putOrCall);
    //   if (expirationDateQuotesMap.has(expiration)) {
    //     let thisQuoteArray = expirationDateQuotesMap.get(expiration);
    //     let newObject = {
    //       id: id,
    //       optionSymbol: optionSymbol,
    //       selected: false,
    //       putOrCall: putOrCall
    //     }
    //     thisQuoteArray.push(newObject);
    //     expirationDateQuotesMap.set(expiration, thisQuoteArray);
    //     id++;
    //   } else {
    //     let newArray = new Array();
    //     let newObject = {
    //       id: id,
    //       optionSymbol: optionSymbol,
    //       selected: false,
    //       putOrCall: putOrCall
    //     }
    //     newArray.push(newObject);
    //     expirationDateQuotesMap.set(expiration, newArray);
    //     id++;
    //   }
    // })

    // let expirationDateQuotesArray = new Array();
    // id = 0;
    // expirationDateQuotesMap.forEach((value, key, map) => {
    //   console.log('value: ', value);
    //   console.log('key: ', key);
    //   console.log('map: ', map);
    //   let object = {
    //     id: id,
    //     selected: false,
    //     expDate: key,
    //     optionSymbolArray: value
    //   }
    //   expirationDateQuotesArray.push(object);
    //   id++;
    // })
    ////console.log('expirationDateSet.length: ', expirationDateSet.length);
    // console.log('expirationDateQuotesMap: ', expirationDateQuotesMap);
    // console.log('expirationDateQuotesArray: ', expirationDateQuotesArray);
    let expirationDateArray = [];
    let id = 0;
    if (expirationDateSet.length > 0) {
      expirationDateSet.forEach((expDate) => {
        let expDateObject = {
          id: { id },
          expDate: expDate,
          selected: false
        }
        expirationDateArray.push(expDateObject);
        id++;
      });
    }
    let stockInfoResponse = await axios.get('https://securedoug.com/stockreact/stockinfo/' + symbol);
    let stockInfo = stockInfoResponse.data[0];

    // this.setState({ optionSymbolsSet: optionSymbolsSet });
    // this.setState({ stockSymbol: symbol });
    // this.setState({ quotes: data });
    this.setState({ stockSymbol: symbol });
    this.setState({ stockInfo: stockInfo });
    this.setState({ stockQuotesMap: stockQuotesMap });
    this.setState({ quoteDateTimeSet: quoteDateTimeSet });
    // this.setState({ expirationDateQuotesMap: expirationDateQuotesMap });
    // this.setState({ expirationDateQuotesArray: expirationDateQuotesArray });
    this.setState({ expirationDateSet: expirationDateSet });
    this.setState({ expirationDateArray: expirationDateArray });
    console.log('App GOT THROUGH getStockSymbol');
  }

  async componentDidMount() {
    //axios.get('http://167.99.4.52:3000/stock')
    console.log('App componentDidMount props: ', this.props);
    console.log('App componentDidMount state: ', this.state);
    let stockSymbol = this.state.stockSymbol;
    console.log('App componentDidMount stockSymbol: ', stockSymbol);
    ////this.getStockSymbol(stockSymbol);
    // axios.get('https://securedoug.com/stockreact/' + stockSymbol)
    //   .then(res => this.setState({ quotes: res.data }))
    // const res = await axios.get('https://securedoug.com/stockreact/' + stockSymbol);
    // console.log('App componentDidMount got data');
    // const { data } = res;
    // const optionSymbolsSet = [...new Set(data.map(item => item.optionSymbol))];
    // const quoteDateTimeSet = [...new Set(data.map(item => item.quoteDateTime))];
    // const expirationDateSet = [...new Set(data.map(item => item.optionSymbol.substr(item.optionSymbol.indexOf("2"), 6)))];

    // let stockQuotesMap = new Map();
    // quoteDateTimeSet.map((quoteDateTime) => {
    //   let underlyingLast = data.find((quote) => quote.quoteDateTime = quoteDateTime).underlyingLast;
    //   stockQuotesMap.set(quoteDateTime, underlyingLast);
    // })
    // console.log('App componentDidMount stockQuotesMap.size: ', stockQuotesMap.size);
    // console.log('App componentDidMount quoteDateTimeSet.length: ', quoteDateTimeSet.length);
    // console.log('App componentDidMount optionSymbolsSet.length: ', optionSymbolsSet.length);
    // console.log('App componentDidMount quotes data.length: ', data.length);
    // console.log('App componentDidMount expirationDateSet.length: ', expirationDateSet.length);

    // this.setState({ optionSymbolsSet: optionSymbolsSet });
    // this.setState({ quotes: data });
    // this.setState({ stockQuotesMap: stockQuotesMap });
    // this.setState({ quoteDateTimeSet: quoteDateTimeSet });
    // this.setState({ expirationDateSet: expirationDateSet });
  }

  render() {
    return (
      <Router>
        <div className="App">
          <div className='container'>
            <Header />
            <Route exact path="/" render={props => (
              <React.Fragment>
                {/* <Quotes quotes={this.state.quotes} /> */}
                <h1>Try either Canvas or Summary2</h1>
                <h3>with Canvas, then try Exp Dates - it sakes a minute to download all those quotes</h3>
                <h5>with Summary2 click twice on pctQDT300Plus to put the results in ASC order</h5>
              </React.Fragment>
            )} />
            {/* <Route path="/canvas" render={this.state.quotes => (
              <React.Fragment>
                <Canvas quotes={this.state.quotes} />
              </React.Fragment>
            )} /> */}
            <Route path="/canvas" children={<Canvas getStockSymbol={this.getStockSymbol.bind(this)} stockSymbol={this.state.stockSymbol} stockInfo={this.state.stockInfo} quotes={this.state.quotes} stockQuotesMap={this.state.stockQuotesMap} optionSymbolsSet={this.state.optionSymbolsSet} quoteDateTimeSet={this.state.quoteDateTimeSet} expirationDateSet={this.state.expirationDateSet} expirationDateQuotesMap={this.state.expirationDateQuotesMap} expirationDateArray={this.state.expirationDateArray} />} />
            <Route path="/about" component={About} />
            <Route path="/summary2" children={<Summary2 getStockSymbol={this.getStockSymbol.bind(this)} />} />
            {/* <Route path="/summary2" component={Summary2} /> */}
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
