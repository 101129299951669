import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

//const element = <FontAwesomeIcon icon={faCaretDown} />

class ChangeStockHeader extends Component {

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {}
    }

    getHeaderStyle = () => {
        return {
            width: '150px',
            height: '20px',
            backgroundColor: '#feb236',
            border: '1px solid green',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '16px'
        }
    }

    getStyle = () => {
        return {
            float: 'left',
            marginLeft: '12px',
            marginRight: '12px'
        }
    }

    render() {
        let changeStockIsShown = this.props.changeStockIsShown;
        let textString = ' Change Stock';
        return (
            <div className='glow-on-hover' changeStockIsShown={this.props.changeStockIsShown} onClick={(e) => this.props.changeStock(e)} style={this.getHeaderStyle()} >
                {changeStockIsShown ?
                    <FontAwesomeIcon icon={faCaretUp} />
                    :
                    <FontAwesomeIcon icon={faCaretDown} />
                }
                {textString}
            </div>
        )
    }
}

export default ChangeStockHeader
